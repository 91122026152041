<template>
    <div id="target-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class="col-xl-8 col-md-8 col-sm-12">
                    <div class="row">
                        <div class="col-xl-4 col-md-6 col-sm-12 mb-2">
                            <InputText :placeholder="$t('campaignCode')" v-model.trim="campaignCodeLocal" />
                        </div>
                        <div class="col-xl-4 col-md-6 col-sm-12 mb-2">
                            <InputSelect :placeholder="$t('status')" v-model="statusLocal"
                                :options="$constant.CAMPAIGN.listStatusPartner" />
                        </div>
                        <div class="col-xl-4 col-md-6 col-sm-12 mb-2">
                            <Daterangepicker :placeholder="$t('fromDateToDate')" v-model="fromDateToDateLocal" />
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 col-sm-12">
                    <button @click="searchData()" class="btn btn-primary mx-2">
                        <span>{{ $t('search') }}</span>
                    </button>
                    <button @click="clearSearch()" class="btn btn-secondary mx-2">
                        <span>{{ $t('clearFilter') }}</span>
                    </button>

                </div>
            </div>
            <div class='row mb-3'>
                <div class='col-xl-12 col-md-12 col-sm-12 d-flex justify-content-end'>
                    <!-- <button type='submit' class='btn btn-primary mx-2'>
                        <router-link :to="this.$constant.ROUTER.createTargetCampaign" class="text-white">
                            <i class="fa fa-plus mx-2"></i>
                            <span class="mx-2">
                                {{ $t('createNewCampaign') }}
                            </span>
                        </router-link>
                    </button> -->
                </div>
            </div>
            <div class='row'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="d-flex justify-content-between align-items-center mb-2">
                            <h5 class='card-title mb-2'>{{ $t('sum') }} {{ numberFormat(entries?.length) }}</h5>
                            <div>
                                <button @click="exportData" class="btn btn-outline-secondary mx-2">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('campaignName') }}</th>
                                        <th scope="col">{{ $t('status') }}</th>
                                        <th scope="col">{{ $t('time') }}</th>
                                        <th scope="col">{{ $t('collaborator') }}</th>
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{ $t('distributorCommissionRate') }}</th>
                                        <th scope="col">{{ $t('collaboratorCommissionRate') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(entry, index) in entries" :key="index">
                                        <tr>
                                            <td>
                                                <p>{{ entry?.get_campaign?.name }}</p>
                                                <p>
                                                    {{ $t('code') }}: {{ entry?.get_campaign?.code }}
                                                </p>
                                            </td>
                                            <td>
                                                <span
                                                    :class="getClassForTargetCampaignStatus($filters.formatStatusCampaignPartner( entry?.get_campaign?.status, (entry.get_campaign.start_time), (entry.get_campaign.end_time)))">
                                                    {{ $filters.formatStatusCampaignPartner( entry?.get_campaign?.status, (entry.get_campaign.start_time), (entry.get_campaign.end_time)) }}
                                                </span>
                                            </td>
                                            <td>{{ $filters.formatStartTimeAndEndTime(entry.get_campaign.start_time, entry.get_campaign.end_time) }}</td>
                                            <td>
                                                <div class="d-flex align-items-center"
                                                    v-if="entry.target_campaign_contributors">
                                                    <div v-for="(contributor, index) in removeDuplicateContributors(entry.target_campaign_contributors)"
                                                        :key="index">
                                                        <span v-if="index < 2">
                                                            {{ contributor.name }}
                                                            <!-- Chỉ thêm dấu phẩy khi có từ 2 contributor trở lên -->
                                                            <span
                                                                v-if="index < 1 && removeDuplicateContributors(entry.target_campaign_contributors).length > 1">,&nbsp;</span>
                                                        </span>
                                                    </div>
                                                    <div v-if="removeDuplicateContributors(entry.target_campaign_contributors).length - 2 > 0"
                                                        class="count-product mx-2">
                                                        +{{
                                                        removeDuplicateContributors(entry.target_campaign_contributors).length
                                                        - 2 }}
                                                    </div>
                                                </div>
                                                <div v-if="entry.status">({{ $t('endedWithContributor') }})</div>
                                                <div v-else>
                                                    <span></span>
                                                </div>
                                            </td>
                                            <td class="info-products">
                                                <div class="image d-flex align-items-center"
                                                    v-if="entry.get_campaign.products && entry.get_campaign.products.length > 2">
                                                    <template v-for="(product, index) in entry.get_campaign.products"
                                                        :key="index">
                                                        <img v-if="index <= 1"
                                                            :src="product.main_image ?? image_default" />
                                                    </template>
                                                    <div class="count-product">+{{ entry.get_campaign.products.length -
                                                        +2 }}</div>
                                                </div>
                                                <div v-else class="image">
                                                    <img v-for="product in entry.get_campaign.products"
                                                        :key="product.id" :src="product.main_image ?? image_default" />
                                                </div>
                                            </td>
                                            <td class="nowrap">
                                                {{  $filters.formatRangerRate(entry.get_campaign.max_rate_target_campaign_product, entry.get_campaign.min_rate_target_campaign_product) }}
                                            </td>
                                            <td class="nowrap">
                                                {{ entry?.target_campaign_contributors.length > 0 ? $filters.formatRangerRateContributor(entry.max_rate_target_campaign_contributor,
                                                    entry.min_rate_target_campaign_contributor) : null}}
                                            </td>
                                            <td class="color_link nowrap">
                                                <span class="mx-1" role="button" @click="handleDetail(entry)">
                                                    <span class="icon_action" :titleCustom="$t('manageCampaign')"><img :src="icon_campaign_manage"/></span>
                                                </span>
                                                <span class="mx-1" role="button">
                                                    <span class="icon_action" @click="handleDetail(entry, 'performance')" :titleCustom="$t('seePerformance')"><img :src="icon_view_performance"/></span>
                                                </span>

                                                <span class="mx-1" @click="showModalStop(entry.target_campaign_id, entry.get_campaign.end_time, entry.get_campaign.start_time)" role="button" v-if="checkCanStopCampaign(entry, $filters.formatStatusCampaignPartner(entry.get_campaign?.status,(entry.get_campaign.start_time), (entry.get_campaign.end_time)))">
                                                    <span class="icon_remove" :titleCustom="$t('stopCampaign')"><img :src="icon_remove"/></span>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>

                            <div id="noData" v-if="entries?.length == 0">
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Paginate :value="paginate" :record="record" />
        </div>
        <Loading :loading="isLoading" />
        <ModalConfirmStop
            :title="$t('titleStopCampaign')"
            :content="this.contentStopCampaign"
            ref="ModalConfirmStop"
            @confirm="stopCampaign"
        />
    </div>
</template>

<script>
import { numberFormat, getClassForTargetCampaignStatus } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import Paginate from '@/components/Paginate.vue';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import Daterangepicker from '@/components/Daterangepicker.vue';
import image_default from '../../../public/images/default-image.jpg';
import ModalConfirmStop from '@/components/Modal/ModalConfirm.vue';
import { mapGetters } from 'vuex';
import i18n from '@/locales/i18nHelper'
import toastr from 'toastr';

import store from "@/store";
import icon_remove from '../../../public/images/icon_remove.png'
import icon_view_performance from '../../../public/images/icon_view_performance.png'
import icon_campaign_manage from '../../../public/images/icon_campaign_manage.png'


export default {
    name: 'TargetCampaignPartner',
    setup() {
        return { numberFormat, getClassForTargetCampaignStatus };
    },
    components: {
        Loading,
        InputText,
        Paginate,
        InputSelect,
        Daterangepicker,
        ModalConfirmStop
    },
    created() {
        this.getCampaignsTarget();
    },
    computed: {
        ...mapGetters({
            userInfo: 'auth/userInfo'
        }),
    },
    data() {
        return {
            isLoading: false,
            image_default: image_default,
            record: this.$constant.PAGE.record,
            paginate: {
                totalRecord: 0,
                currentPage: 1,
                lastPage: 0
            },

            pID: null,
            entries: [],
            campaignCodeLocal: null,
            campaignCode: null,
            statusLocal: null,
            status: null,
            fromDateToDateLocal: null,
            fromDateToDate: null,
            campaign_id: null,
            campaignName: null,
            endTimePlan: null,
            target_campaign_id: null,
            end_campaign: null,
            contentStopCampaign: null,
            icon_remove: icon_remove,
            icon_view_performance: icon_view_performance,
            icon_campaign_manage: icon_campaign_manage,
        }
    },
    methods: {
        async getCampaignsTarget() {
            const res = await store.dispatch('campaignStore/getCampaignsTargetPartner', {
                currentPage: this.paginate.currentPage,
                record: this.record,
                status: this.status,
                campaignCode: this.campaignCode,
                fromDateToDate: this.fromDateToDate,
            });
            if (res && res.data) {
                this.entries = res.data;
                this.paginate.currentPage = res.current_page;
                this.paginate.lastPage = res.last_page;
                this.paginate.totalRecord = res.total;
            }
        },
        handleDetail(entry, tab = null) {
            const campaignId = entry.target_campaign_id;
            // this.$router.push({ name: i18n.global.t('contributorAffiliate') + ' >>>>' + i18n.global.t('targetCampaign'), params: { id: campaignId } });
            this.$router.push({path: this.$constant.ROUTER.detailTargetCampaignPartner, query: {id: campaignId, tab: tab}});
        },
        removeDuplicateContributors(contributors) {
            const uniqueContributors = contributors.reduce((acc, contributor) => {
                const existingContributor = acc.find(item => item.user_id === contributor.user_id);
                if (!existingContributor) {
                    acc.push(contributor);
                }
                return acc;
            }, []);
            return uniqueContributors;
        },
        searchData() {
            this.campaignCode = this.campaignCodeLocal;
            this.status = this.statusLocal;
            this.fromDateToDate = this.fromDateToDateLocal;
            this.getCampaignsTarget();
        },
        clearSearch() {
            this.campaignCode = null;
            this.campaignCodeLocal = null;
            this.status = null;
            this.statusLocal = null;
            this.paginate.currentPage = 1;
            this.fromDateToDate = null;
            this.fromDateToDateLocal = null;
            this.getCampaignsTarget();
        },
        async exportData(){
            const res = await this.$store.dispatch('campaignStore/exportTargetCampaignContributor', {
                status: this.status,
                campaignCode: this.campaignCode,
                fromDateToDate: this.fromDateToDate,
            });
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        async stopCampaign(campaign_id) {              
            const res = await store.dispatch('campaignStore/stopCampaignTargetPartner', {
                partner_id: this.userInfo.id,
                campaign_id: this.target_campaign_id,
                end_time: this.endTimePlanSub,
                end_campaign: this.end_campaign
            });
            if (res && res.status) {
                toastr.success(this.$t('msgRemoveSuccess'));
            } else {
                toastr.error(this.$t('msgRemoveError'));
            }
            this.getCampaignsTarget();
        },
        checkCanStopCampaign(entry, status){
            const seven_day_after = moment().add(7, 'days').format('YYYY-MM-DD HH:mm');
            if(status === this.$t('campaignStatusActive') && moment((entry.end_time || entry.get_campaign.end_time), 'YYYY-MM-DD HH:mm').isBefore(seven_day_after)){
                return false;
            }
            const listStatus = [
                this.$t('campaignStatusDraft'),
                this.$t('campaignStatusClose'),
                this.$t('campaignEnd'),
            ]
            if(listStatus.includes(status)){
                return false;
            }
            return true;
        },

        showModalStop(campaign_id, end_time, start_time) {
            const now = moment().format('YYYY-MM-DD HH:mm:ss');
            this.target_campaign_id = campaign_id;
            this.end_campaign = false;
            if(moment(start_time, 'YYYY-MM-DD HH:mm:ss').isAfter(now)){
                this.endTimePlanSub = end_time;
                this.end_campaign = true;
                this.contentStopCampaign = this.$t('confirmStopThisCampaign');
            }else{
                let time = moment().add(7, 'days').format('HH:mm');
                let date = moment().add(7, 'days').format('DD/MM/YYYY');
                let endTimePlan = null;
                let endTimePlanSub = null;
                if (!end_time) {
                    endTimePlan = moment().add(7, 'days').format('HH:mm ' + i18n.global.t("date") + 'DD/MM/YYYY');
                    endTimePlanSub = moment().add(7, 'days').format('YYYY-MM-DD HH:mm');
                    this.end_campaign = true;
                } else {
                    let endTimeOrigin = moment(end_time);
                    if (moment().add(7, 'days') < endTimeOrigin) {
                        endTimePlan = moment().add(7, 'days').format('HH:mm' + i18n.global.t("date") + 'DD/MM/YYYY');
                        endTimePlanSub = moment().add(7, 'days').format('YYYY-MM-DD HH:mm');
                    } else {
                        // endTimePlanSub = moment(end_time).format('YYYY-MM-DD HH:mm');
                        endTimePlan = moment(end_time).format('HH:mm'+ i18n.global.t("date") +'DD/MM/YYYY');
                        let messages = i18n.global.t('contentStopCampaignStart') + endTimePlan;
                        toastr.error(messages);
                        return;
                    }
                }            
                this.endTimePlan = endTimePlan;
                this.endTimePlanSub = endTimePlanSub;
                this.contentStopCampaign = this.$t('contentStopCampaign', {time: time, date: date});
            }
            this.$refs.ModalConfirmStop.show();
        },
    },
    watch: {
        'paginate.currentPage': function() {
            this.getCampaignsTarget();
        },
    },
};
</script>

<style lang="scss" scoped>
#target-campaign-page {
    .table-responsive {
        .customtable {
            .info-products {
                .image img {
                    // height: 60px !important;
                    width: 50px;
                    transform: scale(1);
                    margin: 1px;
                }

                p {
                    margin-bottom: 0px;
                }
            }

            .count-product {
                width: 40px;
                height: 40px;
                border: 1px solid #dee2e6;
                border-radius: 5px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #dee2e6;
            }
        }
    }
}
</style>
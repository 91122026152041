<template>
    <div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
        data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">

        <Header :routeName="routeName"></Header>

        <Sidebar :menus="menuItems" :routePath="routePath"></Sidebar>

        <div class="page-wrapper">
            <router-view></router-view>
        </div>

    </div>
</template>

<script>
import { defineComponent } from 'vue';
import Header from './Header.vue';
import Sidebar from './Sidebar.vue';
import { ROUTER, PERMISSIONS } from '@/common/constant'
import { useRoute } from 'vue-router';
import icon_chien_dich from '../../../public/images/icon_chien_dich.png'
import icon_don_hang_tiep_thi_lien_ket from '../../../public/images/icon_don_hang_tiep_thi_lien_ket.png'
import icon_hieu_suat from '../../../public/images/icon_hieu_suat.png'
import icon_quan_ly_doi_tac_ctv from '../../../public/images/icon_quan_ly_doi_tac_ctv.png'
import icon_quan_ly_thanh_toan from '../../../public/images/icon_quan_ly_thanh_toan.png'
import icon_dashboard from '../../../public/images/icon_dashboard.png'
import icon_quan_ly_nguon from '../../../public/images/icon_quan_ly_nguon.png'

export default defineComponent({
    name: 'Layout',
    components: {
        Header,
        Sidebar
    },
    data() {
        return {
            menuItems: [
                {
                    label: 'dashboard',
                    url: ROUTER.dashboard,
                    icon: icon_dashboard,
                    key: [this.$constant.PERMISSIONS.ROLE_ADMIN],
                },
                {
                    label: 'dashboard',
                    url: ROUTER.dashboardContributor,
                    icon: icon_dashboard,
                    key: [this.$constant.PERMISSIONS.ROLE_CONTRIBUTOR],
                },
                {
                    label: 'dashboard',
                    url: ROUTER.dashboardPartner,
                    icon: icon_dashboard,
                    key: [this.$constant.PERMISSIONS.ROLE_PARTNER],
                },
                {
                    label: 'campaignSideBar',
                    url: ROUTER.partnerCampaign,
                    icon: icon_chien_dich,
                    key: [this.$constant.PERMISSIONS.ROLE_ADMIN],
                    children: [
                        {
                            label: 'openCampaign',
                            url: ROUTER.openCampaign,
                            icon: '',
                            key: [this.$constant.PERMISSIONS.ROLE_ADMIN],
                        },
                        {
                            label: 'targetCampaign',
                            url: ROUTER.targetCampaign,
                            icon: '',
                            key: [this.$constant.PERMISSIONS.ROLE_ADMIN],
                        },
                    ]
                },
                {
                    label: 'campaignSideBar',
                    url: ROUTER.contributorAffiliate,
                    icon: icon_chien_dich,
                    key: [this.$constant.PERMISSIONS.ROLE_CONTRIBUTOR],
                    children: [
                        {
                            label: 'openCampaign',
                            url: ROUTER.contributorAffiliateOpenCampaign,
                            icon: '',
                            key: [this.$constant.PERMISSIONS.ROLE_CONTRIBUTOR],
                        },
                        {
                            label: 'targetCampaign',
                            url: ROUTER.contributorAffiliateTargetCampaign,
                            icon: '',
                            key: [this.$constant.PERMISSIONS.ROLE_CONTRIBUTOR],
                        },
                    ]
                },

                {
                    label: 'campaignSideBar',
                    url: ROUTER.contributorAffiliate,
                    icon: icon_chien_dich,
                    key: [this.$constant.PERMISSIONS.ROLE_PARTNER],
                    children: [
                    {
                            label: 'openCampaign',
                            url: ROUTER.openCampaignPartner,
                            icon: '',
                            key: [this.$constant.PERMISSIONS.ROLE_PARTNER],
                        },
                        {
                            label: 'targetCampaign',
                            url: ROUTER.targetCampaignPartner,
                            icon: '',
                            key: [this.$constant.PERMISSIONS.ROLE_PARTNER],
                        },
                    ]
                },
                {
                    label: 'partnerManage',
                    url: ROUTER.partner,
                    icon: icon_quan_ly_doi_tac_ctv,
                    key: [this.$constant.PERMISSIONS.ROLE_ADMIN],
                },
                {
                    label: 'manageCollaborator',
                    url: ROUTER.collaborators,
                    icon: icon_quan_ly_doi_tac_ctv,
                    key: [this.$constant.PERMISSIONS.ROLE_PARTNER],
                },
                {
                    label: 'performance',
                    url: ROUTER.performance,
                    icon: icon_hieu_suat,
                    key: [this.$constant.PERMISSIONS.ROLE_ADMIN, this.$constant.PERMISSIONS.ROLE_PARTNER],
                    children: [
                        {
                            label: 'mainAnalyticalData',
                            url: ROUTER.mainAnalyticalData,
                            icon: '',
                            key: [this.$constant.PERMISSIONS.ROLE_ADMIN, this.$constant.PERMISSIONS.ROLE_PARTNER],
                        },
                        {
                            label: 'analyticalCampaign',
                            url: ROUTER.analyticalCampaign,
                            icon: '',
                            key: [this.$constant.PERMISSIONS.ROLE_ADMIN, this.$constant.PERMISSIONS.ROLE_PARTNER],
                        },
                        {
                            label: 'analyticalProduct',
                            url: ROUTER.analyticalProduct,
                            icon: '',
                            key: [this.$constant.PERMISSIONS.ROLE_ADMIN, this.$constant.PERMISSIONS.ROLE_PARTNER],
                        },
                        {
                            label: 'affiliateMarketingAnalysis',
                            url: ROUTER.affiliateMarketingAnalysis,
                            icon: '',
                            key: [this.$constant.PERMISSIONS.ROLE_ADMIN, this.$constant.PERMISSIONS.ROLE_PARTNER],
                        },
                    ]
                },
                {
                    label: 'orders',
                    url: ROUTER.orders,
                    icon: icon_don_hang_tiep_thi_lien_ket,
                    key: [this.$constant.PERMISSIONS.ROLE_ADMIN, this.$constant.PERMISSIONS.ROLE_PARTNER, this.$constant.PERMISSIONS.ROLE_CONTRIBUTOR],
                },
                {
                    label: 'paymentManage',
                    url: ROUTER.payments,
                    icon: icon_quan_ly_thanh_toan,
                    key: [this.$constant.PERMISSIONS.ROLE_ADMIN, this.$constant.PERMISSIONS.ROLE_PARTNER, this.$constant.PERMISSIONS.ROLE_CONTRIBUTOR],
                },
                {
                    label: 'manageResources',
                    url: ROUTER.resources,
                    icon: icon_quan_ly_nguon,
                    key: [this.$constant.PERMISSIONS.ROLE_ADMIN],
                },
            ],
            routePath: '',
            routeName: '',
        }
    },
    mounted() {
        const route = useRoute();
        this.routePath = route.path;
        this.routeName = route.meta?.displayName;
        this.$watch('$route', (newRoute) => {
            this.routePath = newRoute.path;
            this.routeName = newRoute.meta?.displayName;
        });
    },
});
</script>
<template>
    <div class='modal fade' ref="ModalRemovePartner" id='ModalRemovePartner' aria-labelledby='ModalRemovePartner' aria-hidden='true'
        data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-md'>
            <div class='modal-content'>
                <div class='modal-header border-bottom-0'>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body'>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12 text-center'>
                            <span class="modal-title">{{ $t('titleRemovePartner') }}</span>
                        </div>
                    </div>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12 text-center'>
                            <span>{{ $t('contentRemovePartner') }}</span>
                        </div>
                    </div>
                    <div class='row mt-3'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-secondary mx-2' data-bs-dismiss='modal' aria-label='Close'>
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-primary mx-2' @click="confirm">
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import toastr from 'toastr';
export default {
    name: "ModalRemovePartner",
    data(){
        return {
            id: null,
        }
    },
    methods: {
        async confirm(){
            const res = await this.$store.dispatch('partners/removePartner', {id: this.id})
            if(res?.code === 200 && !res?.error){
                toastr.success(this.$t('msgRemoveSuccess'))
                this.hide();
                return;
            }
            toastr.error(this.$t('msgRemoveError'))
        },
        show(id){
            if(!id){
                return;
            }
            this.id = id;
            window.jQuery(this.$refs.ModalRemovePartner).modal('show');
        },
        hide(){
            this.$emit('reload')
            window.jQuery(this.$refs.ModalRemovePartner).modal('hide');
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/Modal/ModalRemovePartner.scss";
</style>

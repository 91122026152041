<template>
    <div id="main-analytical-data-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='row d-flex justify-content-between'>
                                <div class='col-xl-5 col-md-5 col-sm-12 d-flex align-items-center'>
                                    <span v-html="$t('timeSeeData')"></span>
                                    <!-- <Daterangepicker :placeholder="$t('timeOverview')" v-model="dateTimeSearch"
                                        :defaultDate="dateTimeSearch" class="mx-2" /> -->
                                    <InputMonth class="mx-2" v-model="dateTimeSearch" @update:modelValue="changeTime" />
                                    <button class="btn btn-secondary" @click="clearFilter">
                                        <span>{{ $t('clearFilter') }}</span>
                                    </button>
                                </div>
                                <div class='col-xl-3 col-md-3 col-sm-12 text-right'>
                                    <span class="">
                                        {{ $t('lastUpdated') }} {{ this.time ? formatDateCTV(this.time, 'HH:mm DD/MM/YYYY') : null }}
                                    </span>
                                </div>
                            </div>

                            <div class='row d-flex justify-content-between'>
                                <h5>{{ $t('analyticalProduct') }}</h5>
                            </div>

                            <div class='row d-flex justify-content-between'>
                                <ul class='nav nav-tabs' role='tablist'>
                                    <li class='nav-item'>
                                        <a class='nav-link' role='tab'
                                            @click="changeChanel(null)" :class="this.channelId == null ? 'active' : ''">
                                            <span class='hidden-sm-up'></span>
                                            <span class='hidden-xs-down'>{{ $t('allSource') }}</span>
                                        </a>
                                    </li>
                                    <li class='nav-item' v-for="channel in channels" :key="channel.id">
                                        <a class='nav-link' role='tab'
                                            @click="changeChanel(channel.id)" :class="this.channelId == channel.id ? 'active' : ''">
                                            <span class='hidden-sm-up'> </span>
                                            <span class='hidden-xs-down'>{{ channel.name }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class='row d-flex justify-content-between mt-4'>
                                <inputSelectSearch :options="productsList" v-model="productCode"
                                    :defaultOption="defaultProduct" :placeholder="$t('chooseProduct')"
                                    @searchData="searchDataProduct" :defaultValue="productCode"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('indicators') }}</h4>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div role="button" class="col-md-6 col-lg-3 col-xlg-3">
                                    <div class="card card-hover">
                                        <div class="box border">
                                            <label>{{ $t('sales') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(amount_sale) }}đ</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_amount_sale >= 0" class="text-success">▲{{
                                                    rate_amount_sale }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ this.$filters.formatNegativeNumber(rate_amount_sale) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover">
                                        <div class="box border">
                                            <label>{{ $t('productSold') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(products_sold) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_products_sold >= 0" class="text-success">▲{{
                                                    rate_products_sold }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ this.$filters.formatNegativeNumber(rate_products_sold) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover">
                                        <div class="box border">
                                            <label>{{ $t('order') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(quantity_order) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_quantity_order >= 0" class="text-success">▲{{
                                                    rate_quantity_order }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ this.$filters.formatNegativeNumber(rate_quantity_order) 
                                                    }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover">
                                        <div class="box border">
                                            <label>{{ $t('estCommission') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(commission) }}đ</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_commission >= 0" class="text-success">▲{{
                                                    rate_commission }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ this.$filters.formatNegativeNumber(rate_commission)  }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover">
                                        <div class="box border">
                                            <label>{{ $t('roi') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(roi, 2) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_roi >= 0" class="text-success">▲{{ rate_roi }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ this.$filters.formatNegativeNumber(rate_roi) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover">
                                        <div class="box border">
                                            <label>{{ $t('allBuyer') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(all_buyers) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_all_buyers >= 0" class="text-success">▲{{
                                                    rate_all_buyers }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ this.$filters.formatNegativeNumber(rate_all_buyers) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover">
                                        <div class="box border">
                                            <label>{{ $t('newBuyer') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(new_buyer) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_new_buyer >= 0" class="text-success">▲{{ rate_new_buyer
                                                    }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ this.$filters.formatNegativeNumber(rate_new_buyer) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row' v-if="!channelId">
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <h4 class='card-title'>{{ $t('marketingSourceAnalysis') }}</h4>
                            <div class="d-flex justify-content-center" v-if="this.have_data">
                                <DoughnutChart :labelValues="this.doughnutLabels"
                                    :datasetValues="this.doughnutDatasets" />
                            </div>
                            <div id="noData" v-else>
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('partnerTop') }}</h4>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border">
                                        <tr>
                                            <th scope="col">{{ $t('rank') }}</th>
                                            <th scope="col">{{ $t('partnerInfo') }}</th>
                                            <th scope="col">{{ $t('sales') }}</th>
                                            <th scope="col">{{ $t('itemsForSale') }}</th>
                                            <th scope="col">{{ $t('order') }}</th>
                                            <th scope="col">{{ $t('estCommission') }}</th>
                                            <th scope="col">{{ $t('roi') }}</th>
                                            <th scope="col">{{ $t('totalBuyer') }}</th>
                                            <th scope="col">{{ $t('newBuyer') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable">
                                        <tr v-for="(topPartner, index ) in topPartners" :key="index">
                                            <td>
                                                {{ index + 1 }}
                                            </td>
                                            <td>
                                                <span class="text-primary pe-auto" @click="topPartner.name ? linkToPartner(topPartner.name) : ''">
                                                    {{ topPartner.name }}
                                                </span>
                                            </td>
                                            <td>{{ numberFormat(topPartner.sum_amount) }}</td>
                                            <td>{{ numberFormat(topPartner.count_product) }}</td>
                                            <td>{{ numberFormat(topPartner.count_order) }}</td>
                                            <td>{{ numberFormat(topPartner.sum_commission) }}</td>
                                            <td>{{ numberFormat(topPartner.roi, 2) }}</td>
                                            <td>{{ numberFormat(topPartner.count_buyer) }}</td>
                                            <td>{{ numberFormat(topPartner.count_buyer_new) }}</td>
                                        </tr>
                                        <tr v-if="!topPartners || !topPartners.length">
                                            <td colspan="9" class="text-center">
                                                <span>{{ $t('noData') }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, formatDateCTV } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import LineChart from '@/components/ChartJS/LineChart.vue';
import DoughnutChart from '@/components/ChartJS/DoughnutChart.vue';
import i18n from '@/locales/i18nHelper'
import store from '@/store';
import inputSelectSearch from '../../components/Form/InputSelectSearch.vue';
import InputMonth from '@/components/Form/InputMonth.vue'

export default {
    name: 'DetailProductOpenAnalytical',
    setup() {
        return { numberFormat, formatDateCTV };
    },
    components: {
        Daterangepicker,
        Loading,
        LineChart,
        DoughnutChart,
        inputSelectSearch,
        InputMonth,
    },
    created() {
        this.getProductCode();
        this.getChannels(true);
        this.getProductsOpenCampaign(this.productCode);
        // this.getDataRoundProductsOpenCampaign();
        // this.getTopPartnersForOpenProductCode();
    },
    data() {
        return {
            dateTimeSearch: moment().format('YYYY-MM'),
            dateTimeSearchDefault: moment().format('YYYY-MM'),
            channelId: null,
            productsList: [],
            channels: [],
            productName: null,
            productCode: null,
            all_buyers: null,
            amount_sale: null,
            commission: null,
            new_buyer: null,
            products_sold: null,
            quantity_order: null,
            roi: null,
            rate_channelId: null,
            rate_all_buyers: null,
            rate_amount_sale: null,
            rate_commission: null,
            rate_new_buyer: null,
            rate_products_sold: null,
            rate_quantity_order: null,
            rate_roi: null,
            typeSrc: this.$constant.PAGE.TYPE_CHART_SALES,
            labels: [],
            datasets: [],
            nameChart: i18n.global.t('sales'),
            topPartners: [],
            isLoading: false,
            doughnutLabels: [],
            doughnutDatasets: [],
            isCallMain: false,
            defaultProduct: {},
            time: null,
            have_data: false,
            productCodeDefault: null,
        }
    },
    watch: {
        dateTimeSearch() {
            this.channelId = null
            this.getChannels(true);
            this.getPerformanceForOpenPrdCode();
            // this.getDataRoundProductsOpenCampaign();
            // this.getTopPartnersForOpenProductCode();
        },
        productCode(newValue) {
            if (newValue) {
                this.getPerformanceForOpenPrdCode();
                this.getChannels(true);
            }else{
                this.clearData();
            }
        }
    },
    methods: {
        clearData(){
            this.have_data = false;
            this.topPartners = [];
            this.channels = [];
            this.all_buyers = null;
            this.amount_sale = null;
            this.commission = null;
            this.new_buyer = null;
            this.products_sold = null;
            this.quantity_order = null;
            this.roi = null;
            this.rate_channelId = null;
            this.rate_all_buyers = null;
            this.rate_amount_sale = null;
            this.rate_commission = null;
            this.rate_new_buyer = null;
            this.rate_products_sold = null;
            this.rate_quantity_order = null;
            this.rate_roi = null;
            this.time = null;
        },
        async clearFilter(){
            this.channelId = null;
            this.productName = null;
            await this.getProductsOpenCampaign(this.productCodeDefault);
            if(this.productCode != this.productCodeDefault){
                this.productCode = this.productCodeDefault;
            }
            if(this.dateTimeSearchDefault != this.dateTimeSearch){
                this.dateTimeSearch = moment().format('YYYY-MM')
            }
        },
        linkToPartner(name){
            if(this.user && this.user.type == this.$constant.PARTNER.type_partner){
                this.$router.push({name: this.$constant.ROUTER.collaboratorsName, query: {name: name}});
            }else{
                this.$router.push({name: this.$constant.ROUTER.partnerName, query: {name: name}});
            }
        },
        getProductCode() {
            const encodedData = this.$route.query.product_code;
            this.defaultProduct.name = this.$route.query.product_name;
            this.defaultProduct.id = this.$route.query.product_code
            // console.log(this.defaultProduct);
            
            if (encodedData) {
                this.productCode = encodeURIComponent(encodedData);
                this.productCodeDefault = this.productCode;
            }
        },
        searchDataProduct(productName) {
            this.productName = productName;
            this.getProductsOpenCampaign();
        },
        async getProductsOpenCampaign(productCode = null) {
            const res = await this.$store.dispatch('productStore/getProductsOpenCampaignLimit', {
                productName: this.productName,
                productCode: productCode
            });
            if (res) {
                let productsList = [];
                for (let index = 0; index < res.length; index++) {
                    const product = res[index];
                    productsList.push({ id: product.code, name: product.name });
                    if (productCode && productCode.toLowerCase() == product.code.toLowerCase()) {
                        this.defaultProduct.name = product.name;
                        this.defaultProduct.id = product.code
                    }
                }
                this.productsList = productsList;
            }
        },
        async getTopPartnersForOpenProductCode() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getTopPartnersForOpenProductCode', {
                dateTimeSearch: this.dateTimeSearch,
                productCode: this.productCode,
                channelId: this.channelId,
            });
            if (res) {
                this.topPartners = res;
            }
        },
        async getDataRoundProductsOpenCampaign() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getDataRoundProductsOpenCampaign', {
                dateTimeSearch: this.dateTimeSearch,
                productCode: this.productCode,
            });
            if (res) {
                this.doughnutLabels = res.doughnutLabels;
                this.doughnutDatasets = res.doughnutDatasets;
                this.have_data = res.have_data;
            }
        },
        changeChanel(channelId) {
            this.channelId = channelId;
            this.getPerformanceForOpenPrdCode();
            this.getTopPartnersForOpenProductCode();
        },
        async getChannels(statusLoadData) {
            const res = await this.$store.dispatch('marketingChannels/getListMarketingChannelHasRevenue', {
                dateTimeSearch: this.dateTimeSearch,
                product_code: this.productCode,
                campaign_id: this.$constant.CAMPAIGN.open_campaign_id,
            });
            if (res) {
                this.channels = res;
            }
            if (statusLoadData) {
                this.getDataRoundProductsOpenCampaign();
                this.getTopPartnersForOpenProductCode();
            }
        },
        async getPerformanceForOpenPrdCode() {
            if (!this.dateTimeSearch || this.isCallMain) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getPerformanceForOpenPrdId', {
                channelId: this.channelId,
                dateTimeSearch: this.dateTimeSearch,
                productCode: this.productCode,
            });
            if (res) {
                this.all_buyers = res.all_buyers;
                this.amount_sale = res.amount_sale;
                this.commission = res.commission;
                this.new_buyer = res.new_buyer;
                this.products_sold = res.products_sold;
                this.quantity_order = res.quantity_order;
                this.roi = res.roi;
                this.rate_channelId = res.rate_channelId;
                this.rate_all_buyers = res.rate_all_buyers;
                this.rate_amount_sale = res.rate_amount_sale;
                this.rate_commission = res.rate_commission;
                this.rate_new_buyer = res.rate_new_buyer;
                this.rate_products_sold = res.rate_products_sold;
                this.rate_quantity_order = res.rate_quantity_order;
                this.rate_roi = res.rate_roi;
                this.time = res.time
            }
            this.isCallMain = false;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mainAnalyticalData.scss";
</style>
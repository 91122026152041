import { Module } from 'vuex';
import http from '@/services/http';
import { Networks } from '@/services/Networks';
interface PaymentState {
    payment: [];
}

const state: PaymentState = {
    payment: [],
};

const payment: Module<PaymentState, any> = {
    namespaced: true,
    state,
    mutations: {
        setPayment(state, payment: []) {
            state.payment = payment;
        },
    },
    actions: {
        async fetchPaymentInfo({ commit }, params) {
            try {
                const response:any = await http.post(Networks.payment.get_payments, params);
                if(response?.result?.data?.data){
                    commit('setPayment', response?.result?.data?.data);
                }
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },

        async update({ commit }, params) {
            try {
                const response:any = await http.post(Networks.payment.update_payment, params);
                return response;
            } catch (error: any) {
                commit('setError', error?.message || '');
            }
        },
        async exportPayment({ commit }, params)  {
            try {
                const response:any = await http.post(Networks.payment.export_payment, params);                
                if(response.result){
                    return response.result;
                }else{
                    return null;
                }
            } catch (error: any) {
                return {};
            }
        },
    },
    getters: {
        getPaymentLists(state): [] {
            return state.payment;
        }
    },
};

export default payment;

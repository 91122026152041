<template>
    <DoughnutChart :chart-data="chartData" :plugins="plugins" :options="options"/>
</template>

<script>
import { DoughnutChart } from 'vue-chart-3';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { numberFormat } from '@/utils/utils'
export default {
    components: { DoughnutChart },
    props: ['title', 'labelValues', 'datasetValues'],
    data() {
        let chartData = {
            labels: this.labelValues ?? [],
            datasets: this.datasetValues ?? [],
        };

        let plugins = [ChartDataLabels]

        let options = {
            plugins: {
                datalabels : {
                    color: '#ffffff',
                    font: {
                        size: 16,
                        weight: 'bold'
                    },
                    formatter: (value, ctx) => {
                        // if(!value){
                        //     return null;
                        // }
                        // const total = ctx?.dataset?.data.reduce((accumulator, currentValue) => (+accumulator) + (+currentValue), 0);
                        // return (total ? numberFormat((+value / +total) * 100, 2) : 0) + '%';
                        if (!value) {
                            return null;
                        }
                        const total = ctx?.dataset?.data.reduce((accumulator, currentValue) => (+accumulator) + (+currentValue), 0);
                        const percentages = ctx?.dataset?.data.map(val => (+val / +total) * 100);

                        // Làm tròn từng giá trị nhưng đảm bảo tổng = 100%
                        let roundedPercentages = percentages.map((percent, index) => {
                            return index === percentages.length - 1
                                ? (100 - percentages.slice(0, -1).reduce((sum, p) => sum + Math.round(p * 100) / 100, 0))
                                : Math.round(percent * 100) / 100;
                        });

                        // Tìm giá trị phần trăm tương ứng với `value`
                        const index = ctx.dataset.data.indexOf(value);

                        const formatValue = (val) => {
                            if (Number.isInteger(val)) {
                                return val;
                            }
                            return val.toFixed(2);
                        };
                        return formatValue(roundedPercentages[index]) + '%';
                    },
                },
            }
        }

        return { chartData, plugins, options };
    },
    watch: {
        labelValues: {
            handler: function(newValue) {                  
                this.chartData.labels = newValue;                            
            },
            deep: true
        },
        datasetValues: {
            handler: function(newValue) {  
                this.chartData.datasets = newValue;   
            },
            deep: true
        },
    },
};
</script>
<template>
    <div id="dashboard-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('filterTime') }}</h4>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-xl-8 col-md-8 col-sm-12 d-flex align-items-center'>
                                    <InputMonth class="mx-2" v-model="dateTimeSearch" @update:modelValue="changeTime" />
                                    <button type='submit' class='btn btn-secondary' @click="clearData()">
                                        <span>
                                            {{ $t('clearFilter') }}
                                        </span>
                                    </button>
                                </div>
                                <div class='col-xl-4 col-md-4 col-sm-12 text-right'>
                                    {{ $t('lastUpdated') }} <span v-text="this.lastUpdated ? formatDateCTV(this.lastUpdated, 'HH:mm DD/MM/YYYY') : null"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <ul class='nav nav-tabs' role='tablist'>
                                <li class='nav-item' v-for="option in optionsShow" :key="option.id">
                                    <a class='nav-link' role='tab'
                                        @click="changeOption(option.id)" :class="this.optionId == option.id ? 'active' : ''">
                                        <span class='hidden-sm-up'> </span>
                                        <span class='hidden-xs-down'>{{ option.name }}</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="row mt-3">
                                <div role="button" class="col-md-6 col-lg-3 col-xlg-3"
                                    @click="showChart($constant.PAGE.TYPE_CHART_SALES, $t('sales'))">
                                    <div class="card card-hover">
                                        <div class="box border">
                                            <label>{{ $t('sales') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(amount_sale) }}đ</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_amount_sale >= 0" class="text-success">▲{{
                                                    rate_amount_sale ?? 0 }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_amount_sale) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_PRODUCTS_SOLD, $t('productSold'))">
                                        <div class="box border">
                                            <label>{{ $t('productSold') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(products_sold) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_products_sold >= 0" class="text-success">▲{{
                                                    rate_products_sold ?? 0 }}%</span>
                                                <span v-else class="text-danger">&#9660; {{ $filters.formatNegativeNumber(rate_products_sold) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_ORDER, $t('order'))">
                                        <div class="box border">
                                            <label>{{ $t('order') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(quantity_order) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_quantity_order >= 0" class="text-success">▲{{
                                                    rate_quantity_order ?? 0 }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_quantity_order) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_ROI, 'ROI')">
                                        <div class="box border">
                                            <label>{{ $t('roi') }}</label>
                                            <h3 class="text-dark">{{ roi }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_roi >= 0" class="text-success">▲{{ rate_roi ?? 0 }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_roi) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_COMMISSION, $t('estCommission'))">
                                        <div class="box border">
                                            <label>{{ $t('estCommission') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(commission) }}đ</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_commission >= 0" class="text-success">▲{{
                                                    rate_commission ?? 0 }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_commission) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_COMMISSION_CONTRIBUTOR, $t('payCommissionContributor'))">
                                        <div class="box border">
                                            <label>{{ $t('payCommissionContributor') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(commission_contributor) }}đ</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_commission_contributor >= 0" class="text-success">▲{{
                                                    rate_commission_contributor ?? 0 }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_commission_contributor) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_ALL_BUYER, $t('allBuyer'))">
                                        <div class="box border">
                                            <label>{{ $t('allBuyer') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(all_buyers) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_all_buyers >= 0" class="text-success">▲{{
                                                    rate_all_buyers ?? 0 }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_all_buyers) }}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div role="button" class="card card-hover"
                                        @click="showChart($constant.PAGE.TYPE_CHART_ALL_BUYER_NEW, $t('newBuyer'))">
                                        <div class="box border">
                                            <label>{{ $t('newBuyer') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(new_buyer) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span v-if="rate_new_buyer >= 0" class="text-success">▲{{ rate_new_buyer ?? 0
                                                    }}%</span>
                                                <span v-else class="text-danger">&#9660;{{ $filters.formatNegativeNumber(rate_new_buyer)}}%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <template v-if="isShowChart">
                                <div v-if="optionId == 1" class="row">
                                     <div class="col-md-12 col-lg-12 col-xlg-12">
                                        <h6>{{ nameChart }}</h6>
                                        <BarChart :labelValues="barLabels" :datasetValues="barDatasets" :isStack="true" :tooltipPrefix="$t('sales')"/>
                                    </div>
                                </div>
                                <div v-else class="row">
                                    <div class="col-lg-12">
                                        <label>{{ nameChart }}</label>
                                        <LineChart :labelValues="labels" :datasetValues="datasets"/>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="optionId != 2" class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <h4 class='card-title'>{{ $t('marketingSourceAnalysis') }}</h4>
                            <div class="d-flex justify-content-center">
                                <DoughnutChart :labelValues="doughnutLabels"
                                    :datasetValues="doughnutDatasets" v-if="have_data"/>
                                <div id="noData" v-if="!have_data">
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class='row' v-if="optionId != 2">
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <h4 class='card-title'>{{ $t('analyticalProduct') }}</h4>
                            <BarChart :labelValues="labelsProducts" :datasetValues="datasetsProducts" :tooltipPrefix="$t('commission')" v-if="this.labelsProducts && this.labelsProducts?.length"/>
                            <div id="noData" v-else>
                                <span>{{ $t('noData') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class='row' v-if="optionId != 2">
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center justify-content-between mb-1'>
                                <div>
                                    <h4 class='card-title'>{{ $t('productTop') }}</h4>
                                    <InputSelectSearch :options="this.products" :optionValue="'code'" :defaultValue="this.filters.product_code" @searchData="getListProductHaveOrder" @update:modelValue="selectProduct" :placeholder="$t('selectProductName')" class="min-w-350"/>
                                </div>
                                <button class="btn btn-outline-secondary" @click="exportTopProduct">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border">
                                        <tr>
                                            <th scope="col">{{ $t('rank') }}</th>
                                            <th scope="col">{{ $t('productInformation') }}</th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort"
                                                    :name="$t('sales')" :field="'amount'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort"
                                                    :name="$t('estCommission')" :field="'commissions'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort"
                                                    :name="$t('commissionReturnContributor')" :field="'commission_contributor'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort"
                                                    :name="$t('commissionReality')" :field="'commission_reality'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort"
                                                    :name="$t('numberOfProductSold')" :field="'quantity'" />
                                            </th>
                                            <th scope="col">{{ $t('newBuyer') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable">
                                        <template v-for="(product, index) in topProduct" :key="product">
                                            <tr>
                                                <td class="text-danger">{{ index + 1 }}</td>
                                                <td>
                                                    <div class="info-products d-flex">
                                                        <div class="image">
                                                            <img :src="product?.product_image"
                                                                v-if="product?.product_image" />
                                                        </div>
                                                        <div class="product mx-1">
                                                            <p>
                                                                <a class="sidebar-link has-arrow waves-effect waves-dark"
                                                                    target="_blank"
                                                                    :href="urlPharmaProduct(product?.product_slug)"
                                                                    aria-expanded="false">
                                                                    {{ product?.product_name }}
                                                                </a>
                                                            </p>
                                                            <p>
                                                                {{ $t('skuId') }}: {{ product?.product_sku }}
                                                            </p>
                                                            <p>
                                                                {{ $t('price') }}: {{
                                                                    numberFormat(product?.product_price) }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{ numberFormat(product?.amount) }}</td>
                                                <td>{{ numberFormat(product?.commissions) }}</td>
                                                <td>{{ numberFormat(product?.commission_contributor) }}</td>
                                                <td>{{ numberFormat(product?.commission_reality) }}</td>
                                                <td>{{ numberFormat(product?.quantity) }}</td>
                                                <td>{{ numberFormat(product?.new_customers) }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                                <div id="noData" v-if="this.topProduct?.length == 0">
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row' v-if="optionId == 3">
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center justify-content-between mb-2'>
                                <div>
                                    <h4 class='card-title'>
                                        <span>
                                            {{ $t('topContributor') }}
                                        </span>
                                    </h4>
                                </div>
                                <button class="btn btn-outline-secondary" @click="exportTopPartnerForAnalysisProduct">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border">
                                        <tr>
                                            <th scope="col">{{ $t('rank') }}</th>
                                            <th scope="col">{{ $t('contributorInfo') }}</th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataContributor.field" @onsort="sortContributor" :name="$t('sales')" :field="'amount'"/>
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataContributor.field" @onsort="sortContributor" :name="$t('estCommission')" :field="'commissions'"/>
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataContributor.field" @onsort="sortContributor" :name="$t('commissionReturnContributor')" :field="'commission_contributor'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortDataContributor.field" @onsort="sortContributor" :name="$t('commissionReality')" :field="'commission_reality'" />
                                            </th>
                                            <th scope="col">{{ $t('newBuyer') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable">
                                        <template v-for="(contributor, index) in topContributors" :key="contributor">
                                            <tr>
                                                <td class="text-danger text-center text-bold">
                                                    {{ index + 1 }}
                                                </td>
                                                <td>
                                                    <span class="text-primary pe-auto" @click="contributor?.partner_name ? linkToPartner(contributor?.partner_name) : ''">{{ contributor?.partner_name }}</span>
                                                </td>
                                                <td>{{ numberFormat(contributor?.amount) }}</td>
                                                <td>{{ numberFormat(contributor?.commissions) }}</td>
                                                <td>{{ numberFormat(contributor?.commission_contributor) }}</td>
                                                <td>{{ numberFormat(contributor?.commission_reality) }}</td>
                                                <td>{{ numberFormat(contributor?.new_customers) }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                                <div id="noData" v-if="this.topContributors?.length == 0">
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Tab doanh thu cá nhân -->
                <div class='row' v-if="optionId == 2">
                    <div class='col-md-12'>
                        <div class='card'>
                            <div class='card-body'>
                                <div class='mb-1'>
                                    <h4 class='card-title'>{{ $t('top5product') }}</h4>
                                    <ul class='nav nav-tabs' role='tablist'>
                                        <li class='nav-item' v-for="option in optionsSortTopProduct" :key="option.id">
                                            <a class='nav-link' role='tab'
                                                @click="changeOptionTopProduct(option.id, option.field)" :class="this.optionTopProductId == option.id ? 'active' : ''">
                                                <span class='hidden-sm-up'> </span>
                                                <span class='hidden-xs-down'>{{ option.name }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="table-responsive">
                                    <table class="table table-index table-bordered">
                                        <thead class="thead-light border">
                                            <tr>
                                                <th scope="col">{{ $t('rank') }}</th>
                                                <th scope="col">{{ $t('productInformation') }}</th>
                                                <th scope="col">{{ $t('sales') }}</th>
                                                <th scope="col">{{ $t('numberOfProductSold') }}</th>
                                                <th scope="col">{{ $t('order') }}</th>
                                                <th scope="col">{{ $t('estCommission') }}</th>
                                            </tr>
                                        </thead>
                                        <tbody class="customtable">
                                            <template v-for="(product, index) in topProduct" :key="product">
                                                <tr>
                                                    <td class="text-danger">{{ index + 1 }}</td>
                                                    <td>
                                                        <div class="info-products d-flex">
                                                            <div class="image">
                                                                <img :src="product?.product_image"
                                                                    v-if="product?.product_image" />
                                                            </div>
                                                            <div class="product mx-1">
                                                                <p>
                                                                    <a class="sidebar-link has-arrow waves-effect waves-dark"
                                                                        target="_blank"
                                                                        :href="urlPharmaProduct(product?.product_slug)"
                                                                        aria-expanded="false">
                                                                        {{ product?.product_name }}
                                                                    </a>
                                                                </p>
                                                                <p>
                                                                    {{ $t('skuId') }}: {{ product?.product_sku }}
                                                                </p>
                                                                <p>
                                                                    {{ $t('price') }}: {{
                                                                        numberFormat(product?.product_price) }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{{ numberFormat(product?.amount) }}</td>
                                                    <td>{{ numberFormat(product?.quantity) }}</td>
                                                    <td>{{ numberFormat(product?.orders) }}</td>
                                                    <td>{{ numberFormat(product?.commissions) }}</td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                    <div id="noData" v-if="this.topProduct?.length == 0">
                                        <span>{{ $t('noData') }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <!-- Hết Tab doanh thu cá nhân -->

        </div>
        <Loading :loading="isLoading"/>
    </div>
</template>

<script>
import { numberFormat, numberFormatABS, formatDateCTV } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import i18n from '@/locales/i18nHelper';
import BarChart from '@/components/ChartJS/BarChart.vue';
import DoughnutChart from '@/components/ChartJS/DoughnutChart.vue';
import InputMonth from '@/components/Form/InputMonth.vue';
import LineChart from '@/components/ChartJS/LineChart.vue';
import { mapGetters } from 'vuex';
import TableSortField from '@/components/TableSortField.vue';
import InputSelectSearch from '@/components/Form/InputSelectSearch.vue'

export default {
    name: 'Dashboard',
    setup() {
        return { numberFormat, numberFormatABS, formatDateCTV };
    },
    components: {
        Loading,
        BarChart,
        DoughnutChart,
        InputMonth,
        LineChart,
        InputSelectSearch,
        TableSortField
    },
    created() {
        this.getDataForChannelMainAnalysis();
        this.getDataBarChartStack();
        this.getDataRoundChartForChannel();
        this.getDataTopCommissionForBarChart();
        this.getDataTopProductForAnalysisProduct();
        this.getDataTopPartnerForAnalysisProduct();
        this.getListProductHaveOrder();
    },
    data(){
        return {
            all_buyers: null,
            amount_sale: null,
            commission: null,
            new_buyer: null,
            products_sold: null,
            quantity_order: null,
            roi: null,
            rate_channelId: null,
            rate_all_buyers: null,
            rate_amount_sale: null,
            rate_commission: null,
            rate_new_buyer: null,
            rate_products_sold: null,
            rate_quantity_order: null,
            rate_roi: null,
            commission_contributor: null,
            dateTimeSearch: moment().format('YYYY-MM'),
            dateTimeSearchDefault: moment().format('YYYY-MM'),
            optionId: 1,
            optionsShow: [
                {id: 1, name: i18n.global.t('sumRevenue')},
                {id: 2, name: i18n.global.t('personalRevenue')},
                {id: 3, name: i18n.global.t('personalContributor')},
            ],
            barLabels: [],
            barDatasets: [],
            nameChart: i18n.global.t('sales'),
            typeSrc: this.$constant.PAGE.TYPE_CHART_SALES,
            doughnutLabels: [],
            doughnutDatasets: [],
            have_data: false,
            isShowChart: true,
            labelsProducts: [],
            datasetsProducts: [],
            sortData: {
                field: '',
                direction: '',
            },
            sortDataContributor: {
                field: '',
                direction: '',
            },
            filters: {
                get_all: true,
                only_partner: false,
                only_contributor: false,
                product_code: null,
            },
            optionsSortTopProduct: [
                {id: 1, name: i18n.global.t('maxQuantitySold'), field: 'amount'},
                {id: 2, name: i18n.global.t('maxCommission'), field: 'commissions'},
            ],
            optionTopProductId: 1,
            lastUpdated: null,
            rate_commission_contributor: null,
        }
    },
    computed: {
        ...mapGetters({
            topProduct: 'performanceStore/getDataTopProductForAnalysisProduct',
            topContributors: 'performanceStore/getDataTopPartnerForAnalysisProduct',
            products: 'productStore/products_have_order',
        })
    },
    watch: {
        dateTimeSearch() {
            this.getDataForChannelMainAnalysis();
            this.getDataBarChartStack();
            this.getDataRoundChartForChannel();
            this.getDataTopCommissionForBarChart();
            this.getDataBarChartForChannel();
            this.getDataTopProductForAnalysisProduct();
            this.getDataTopPartnerForAnalysisProduct();
            this.getListProductHaveOrder();
        }
    },
    methods: {
        async getDataBarChartForChannel() {            
            if (!this.dateTimeSearch || (this.optionId == 1)) {
                return;
            }
            this.isShowChart = false;
            const res = await this.$store.dispatch('performanceStore/getDataBarChartForChannel', {
                dateTimeSearch: this.dateTimeSearch,
                typeSrc: this.typeSrc,
                typeForPartner: this.optionId
            });
            if (res) {
                this.datasets = res.datasets
                this.labels = res.labels
            }
            this.isShowChart = true;
        },
        async getDataTopCommissionForBarChart() {
            if (!this.dateTimeSearch || this.optionId == 2) {
                return;
            }
            this.isShowChart = false;
            const res = await this.$store.dispatch('performanceStore/getDataTopCommissionForBarChart', {
                dateTimeSearch: this.dateTimeSearch,
                // typeSrc: this.typeSrc
                typeForPartner: this.optionId
            });            
            if (res) {
                this.labelsProducts = res.labelsProducts
                this.datasetsProducts = res.datasetsProducts;   
            }                
            this.isShowChart = true;   
        },
        async getDataRoundChartForChannel() {
            if (!this.dateTimeSearch || this.optionId == 2) {
                return;
            }
            this.doughnutLabels = [];
            this.doughnutDatasets = [];
            const res = await this.$store.dispatch('performanceStore/getDataRoundChartForChannel', {
                dateTimeSearch: this.dateTimeSearch,
                typeForPartner: this.optionId
            });
            if (res) {
                this.doughnutLabels = res?.doughnutLabels;
                this.doughnutDatasets = res?.doughnutDatasets;
                this.have_data = res?.have_data;                
            }
        },
        changeOption(optionId) {            
            this.optionId = optionId;
            this.typeSrc = this.$constant.PAGE.TYPE_CHART_SALES,
            this.filters.get_all = this.optionId == 1 ? true : false,
            this.filters.only_partner = this.optionId == 2 ? true : false,
            this.filters.only_contributor = this.optionId == 3 ? true : false,
            this.getDataForChannelMainAnalysis();
            this.getDataRoundChartForChannel();
            this.getDataBarChartForChannel();
            this.getDataTopPartnerForAnalysisProduct();
            this.getDataTopProductForAnalysisProduct();
            this.getDataTopCommissionForBarChart();
        },
        async getDataForChannelMainAnalysis() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getDataForChannelMainAnalysis', {
                dateTimeSearch: this.dateTimeSearch,
                typeForPartner: this.optionId
            });
            if (res) {
                this.all_buyers = res.all_buyers;
                this.amount_sale = res.amount_sale;
                this.commission = res.commission;
                this.new_buyer = res.new_buyer;
                this.products_sold = res.products_sold;
                this.quantity_order = res.quantity_order;
                this.roi = res.roi;
                this.rate_channelId = res.rate_channelId;
                this.rate_all_buyers = res.rate_all_buyers;
                this.rate_amount_sale = res.rate_amount_sale;
                this.rate_commission = res.rate_commission;
                this.rate_new_buyer = res.rate_new_buyer;
                this.rate_products_sold = res.rate_products_sold;
                this.rate_quantity_order = res.rate_quantity_order;
                this.rate_roi = res.rate_roi;
                this.lastUpdated = res.last_updated;
                this.commission_contributor = res.commission_contributor;
                this.rate_commission_contributor = res.rate_commission_contributor;
            }
        },
        async getDataBarChartStack() {
            if (!this.dateTimeSearch || (this.optionId != 1)) {
                return;
            }
            this.isShowChart = false;
            const res = await this.$store.dispatch('performanceStore/getDataBarChartStack', {
                dateTimeSearch: this.dateTimeSearch,
                typeSrc: this.typeSrc
            });            
            if (res) {
                this.barDatasets = res.datasets
                this.barLabels = res.labels;   
            }                
            this.isShowChart = true;                               
        },

        clearData() {
            this.filters = {
                get_all: true,
                only_partner: false,
                only_contributor: false,
                product_code: null,
            }
            this.sortData = {
                field: '',
                direction: '',
            }
            this.sortDataContributor = {
                field: '',
                direction: '',
            }
            this.optionTopProductId = 1
            this.optionId = 1
            if(this.dateTimeSearchDefault != this.dateTimeSearch){
                this.dateTimeSearch = moment().format('YYYY-MM');
            }else{
                this.getDataForChannelMainAnalysis();
                this.getDataBarChartStack();
                this.getDataRoundChartForChannel();
                this.getDataTopCommissionForBarChart();
                this.getDataBarChartForChannel();
                this.getDataTopProductForAnalysisProduct();
                this.getDataTopPartnerForAnalysisProduct();
                this.getListProductHaveOrder();
            }
        },
        // async getRevenue() {
        //     const res = await this.$store.dispatch('performanceStore/getRevenueDashboard', {
        //         dateTimeSearch: this.dateTimeSearch,    
        //     });  
        //     if (res) {
        //         this.revenues = res.revenues;    
        //         this.totalRevenue = res.totalRevenue;    
        //         this.countOrder = res.countOrder;   
        //         this.sumQuantityBuy = res.sumQuantityBuy;   
        //         this.buyerNew = res.buyerNew;
        //         this.rateTotalRevenue = res.rateTotalRevenue;
        //     }
        // },
        showChart(typeSrc, nameChart) {            
            this.typeSrc = typeSrc;
            this.nameChart = nameChart;
            this.getDataBarChartStack();
            this.getDataBarChartForChannel();
        },
        async getDataTopProductForAnalysisProduct() {
            await this.$store.dispatch('performanceStore/getDataTopProductForAnalysisProduct', {time: this.dateTimeSearch, ...this.filters, ...this.sortData });
        },
        sort(field, direction){
            this.sortData.field = field;
            this.sortData.direction = direction;
            this.getDataTopProductForAnalysisProduct();
        },
        async getDataTopPartnerForAnalysisProduct(){
            if(this.optionId != 3){
                return;
            }
            await this.$store.dispatch('performanceStore/getDataTopPartnerForAnalysisProduct', {time: this.dateTimeSearch, ...this.filters, ...this.sortDataContributor});
        },
        sortContributor(field, direction){
            this.sortDataContributor.field = field;
            this.sortDataContributor.direction = direction;
            this.getDataTopPartnerForAnalysisProduct();
        },
        async exportTopProduct(){
            const res = await this.$store.dispatch('performanceStore/exportTopProductForAnalysisProduct', {time: this.dateTimeSearch, ...this.filters, ...this.sortData});
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        async exportTopPartnerForAnalysisProduct(){
            if(this.optionId != 3){
                return;
            }
            const res = await this.$store.dispatch('performanceStore/exportTopPartnerForAnalysisProduct', {time: this.dateTimeSearch, ...this.filters, ...this.sortDataContributor});
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        async getListProductHaveOrder(keyword = null){
            await this.$store.dispatch('productStore/getListProductHaveOrder', {keyword: keyword, time: this.dateTimeSearch});
        },
        selectProduct(code){
            this.filters.product_code = code;
            this.getDataTopProductForAnalysisProduct();
            this.getDataTopPartnerForAnalysisProduct();
        },
        changeOptionTopProduct(optionId, field) {            
            this.optionTopProductId = optionId;
            this.sortData.field = field;
            this.sortData.direction = 'desc';
            this.getDataTopProductForAnalysisProduct();
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/dashboard.scss";
</style>
<template>
    <div>
        <BarChart :chartData="chartData" :options="options" />
    </div>
</template>

<script>
import { BarChart } from 'vue-chart-3';
export default {
    components: {
        BarChart
    },
    props: ['title', 'labelValues', 'datasetValues', 'tooltipPrefix', 'isStack'],
    data() {
        let chartData = {
            labels: this.labelValues ?? [],
            datasets: this.datasetValues ?? []
        };

        let options = {
            responsive: true,
            scales: {
                x: {
                    ticks: {
                        callback: function(value, index, values) {
                            let label = this.getLabelForValue(value);
                            return label.length > 20 ? label.substr(0, 20) + '...' : label;
                        }
                    }
                },
                y: {
                    beginAtZero: true
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            const value = context.raw;
                            const formattedValue = new Intl.NumberFormat().format(value);
                            return `${this.tooltipPrefix || this.$t('quantity')}: ${formattedValue}`;
                        }
                    }
                }
            },
        };
        if (this.isStack) {
            options.scales.x.stacked = true;
            options.scales.y.stacked = true;
        }

        return {
            chartData,
            options
        };
    },
    watch: {
        labelValues: {
            handler: function (newData) {
                this.chartData = {
                    ...this.chartData,
                    labels: newData
                };
            },
            deep: true
        },
        datasetValues: {
            handler: function (newData) {
                this.chartData = {
                    ...this.chartData,
                    datasets: newData
                };
            },
            deep: true
        },
    }
};
</script>

<style scoped>
</style>
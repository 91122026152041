<template>
    <button @click="login" type="button">
        <img src="../../public/assets/images/Google.png" height="20"/>
        {{ $t('loginGoogle') }}
    </button>
</template>

<script>
import { googleAuthCodeLogin } from "vue3-google-login"
import axios from "axios";
import toastr from "toastr";
export default {
    name: "LoginGoogle",
    components: {
    },
    methods: {
        login() {
            googleAuthCodeLogin().then((response) => {
                if (response?.code) {
                    this.getUserInfo(response?.code);
                }
            }).catch((error) => {
                console.error(error);
            })
        },
        async getUserInfo(code) {
            const clientId = process.env.VUE_APP_GOOGLE_CLIENT_ID;
            const clientSecret = process.env.VUE_APP_GOOGLE_CLIENT_SECRET;
            const redirectUri = process.env.VUE_APP_GOOGLE_REDIRECT_URL;
            try {
                const tokenResponse = await axios.post(process.env.VUE_APP_GOOGLE_OAUTH2_TOKEN_URL, {
                    code,
                    client_id: clientId,
                    client_secret: clientSecret,
                    redirect_uri: redirectUri,
                    grant_type: 'authorization_code'
                });
                const accessToken = tokenResponse.data.access_token;
                const userResponse = await axios.get(process.env.VUE_APP_GOOGLE_OAUTH2_GET_USER_URL, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });
                if(userResponse && userResponse.data){
                    const params = {...userResponse.data, provider: 'google'}
                    const res = await this.$store.dispatch('auth/loginSocial', params);
                    if(res?.code === 200){
                        this.$store.dispatch('auth/setUserActive', res?.data?.active);
                        if(res?.data?.type == this.$constant.PARTNER.type_colloborator){
                            location.replace(this.$constant.ROUTER.dashboardContributor)
                        }else if(res?.data?.type == this.$constant.PARTNER.type_partner){
                            location.replace(this.$constant.ROUTER.dashboardPartner)
                        }else{
                            location.replace(this.$constant.ROUTER.dashboard)
                        }
                        return;
                    }
                    if(res?.error){
                        toastr.error(res?.message)
                    }
                }
            } catch (error) {
                console.error('Lỗi khi lấy thông tin người dùng:', error);
                return null;
            }
        }
    }
}
</script>
<template>
    <div id="dashboard-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('filterTime') }}</h4>
                                </div>
                            </div>
                            <div class='row'>
                                <div class='col-xl-2 col-md-4 col-sm-6'>
                                    <Daterangepicker :placeholder="$t('createdAt')" v-model="dateTimeSearch" :modelValue="dateTimeSearch"/>
                                </div>
                                <div class='col-xl-10 col-md-8 col-sm-6'>
                                    <button type='submit' class='btn btn-secondary' @click="clearData()">
                                        <span>
                                            {{ $t('clearFilter') }}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='row'>
                                <div class="col-xl-3 col-md-5 col-sm-12">
                                    <div class="row">
                                        <div class="container">
                                            <div class="card card-hover">
                                                <div class="box border section">
                                                    <label>{{ $t('totalSales')}}</label>
                                                    <h3 class="text-dark">{{ numberFormat(totalRevenue) }}đ</h3>
                                                    <div class="d-flex justify-content-between">
                                                        <span>{{ $t('compareTheSamePeriod')}}</span>
                                                        <span v-if="rateTotalRevenue >= 0" class="text-success">▲{{ numberFormat(rateTotalRevenue, 2) }}%</span>
                                                        <span v-else class="text-danger">&#9660;{{ numberFormat(rateTotalRevenue, 2) }}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="icon" v-if="revenues && revenues.length">
                                                <font-awesome-icon :icon="['fas', 'equals']" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-9 col-md-7 col-sm-12">
                                    <div class="row">
                                        <div class="container justify-content-start">
                                            <template v-if="revenues && revenues.length">
                                                <template  v-for="(revenue, index) in revenues" :key="index">
                                                    <div class="card card-hover">
                                                        <div class="box border section">
                                                            <label>{{ revenue.channel ? revenue.channel.name : null }}</label>
                                                            <h3 class="text-dark">{{ numberFormat(revenue.sum_amount) }}đ</h3>
                                                            <div class="d-flex justify-content-between">
                                                                <span>{{ $t('compareTheSamePeriod')}}</span>
                                                                <span v-if="revenue.rate >= 0" class="text-success">▲{{ numberFormat(revenue.rate, 2) }}%</span>
                                                                <span v-else class="text-danger">&#9660;{{ numberFormat(revenue.rate, 2) }}%</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="index < (revenues.length -1)" class="icon">
                                                        <font-awesome-icon :icon="['fas', 'plus']" />
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class="row">
                                <div class="col-xl-3 col-md-3 col-sm-12">
                                    <ul class="table-statistic">
                                        <li :class="typeSrc == $constant.PAGE.TYPE_CHART_SALES ? 'border active': 'border'" >
                                            <a class="text-dark" @click="showChart($constant.PAGE.TYPE_CHART_SALES, $t('sales'))">
                                                <span>{{ $t('sales')}}</span>
                                                <span>{{ numberFormat(totalRevenue) }}</span>
                                            </a>
                                        </li>
                                        <li :class="typeSrc == $constant.PAGE.TYPE_CHART_ORDER ? 'border active': 'border'" >
                                            <a class="text-dark" @click="showChart($constant.PAGE.TYPE_CHART_ORDER, $t('order'))">
                                                <span>{{ $t('order')}}</span>
                                                <span>{{ numberFormat(countOrder) }}</span>
                                            </a>
                                        </li>
                                        <li :class="typeSrc == $constant.PAGE.TYPE_CHART_PRODUCTS_SOLD ? 'border active': 'border'">
                                            <a class="text-dark" @click="showChart($constant.PAGE.TYPE_CHART_PRODUCTS_SOLD, $t('productSold'))">
                                                <span>{{ $t('productSold')}}</span>
                                                <span>{{ numberFormat(sumQuantityBuy) }}</span>
                                            </a>
                                        </li>
                                        <li :class="typeSrc == $constant.PAGE.TYPE_CHART_ALL_BUYER_NEW ? 'border active': 'border'">
                                            <a class="text-dark" @click="showChart($constant.PAGE.TYPE_CHART_ALL_BUYER_NEW, $t('newBuyer'))">
                                                <span>{{ $t('newCustomer')}}</span>
                                                <span>{{ numberFormat(buyerNew) }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-xl-9 col-md-9 col-sm-12 mb-4">
                                    <LineChart :title="nameChart" :labelValues="this.labels" :datasetValues="this.datasets"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading"/>
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import LineChart from '@/components/ChartJS/LineChart.vue';
import i18n from '@/locales/i18nHelper' 

export default {
    name: 'Dashboard',
    setup() {
        return { numberFormat };
    },
    components: {
        Daterangepicker,
        Loading,
        LineChart
    },
    created() {
        this.getRevenue();
        this.getDataBarChartForChannel();
    },
    data(){
        return {
            totalRevenue: 0,
            dateTimeSearch: moment().startOf('month').format('YYYY-MM-DD')+'_'+moment().format('YYYY-MM-DD'),
            countOrder: 0,   
            sumQuantityBuy: 0,  
            buyerNew: 0,
            typeSrc: this.$constant.PAGE.TYPE_CHART_SALES,
            nameChart: i18n.global.t('sales'),
            isLoading: false,
            labels: ['01/07', '02/07', '03/07', '04/07', '05/07', '06/07', '07/07', '08/07', '09/07', '10/07'],
            datasets: [],
            labels: [],
            rateTotalRevenue: 0,
        }
    },
    watch: {
        dateTimeSearch() {
            this.getRevenue();
            this.getDataBarChartForChannel()
        }
    },
    methods: {
        clearData() {
            this.dateTimeSearch = moment().startOf('month').format('YYYY-MM-DD')+'_'+moment().format('YYYY-MM-DD');
        },
        async getRevenue() {
            const res = await this.$store.dispatch('performanceStore/getRevenueDashboard', {
                dateTimeSearch: this.dateTimeSearch,    
            });  
            if (res) {
                this.revenues = res.revenues;    
                this.totalRevenue = res.totalRevenue;    
                this.countOrder = res.countOrder;   
                this.sumQuantityBuy = res.sumQuantityBuy;   
                this.buyerNew = res.buyerNew;
                this.rateTotalRevenue = res.rateTotalRevenue;
            }
        },
        showChart(typeSrc, nameChart) {            
            this.typeSrc = typeSrc;
            this.nameChart = nameChart;
            this.getDataBarChartForChannel();
        },
        async getDataBarChartForChannel() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getDataBarChartForChannel', {
                dateTimeSearch: this.dateTimeSearch,
                typeSrc: this.typeSrc
            });            
            if (res) {
                this.datasets = res.datasets
                this.labels = res.labels
            }
        },
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/dashboard.scss";
</style>
<template>
    <div id="input-text-append__form">
        <div class="input-group">
            <InputNumber class="flex-1" :placeholder="placeholder" v-model.trim="model" :errors="errors" :readonly="readonly" :max="max" :min="min" :label="label" :disabled="disabled" v-if="inputType == 'number'"/>
            <InputText class="flex-1" :placeholder="placeholder" v-model.trim="model" :errors="errors" :readonly="readonly" :max="max" :min="min" :label="label" :disabled="disabled" :type="inputType" v-else/>
            <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2" v-text="iconAppend ? iconAppend : '%'"></span>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from './InputText.vue';
import InputNumber from './InputNumber.vue';
export default {
    name: "InputTextAppend",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'readonly', 'max', 'min', 'id', 'iconAppend', 'type', 'disabled'],
    components: {
        InputText,
        InputNumber
    },
    data() {
        return {
            model: this.modelValue,
            inputType: this.type ? this.type : 'number' 
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.model = newValue;
        },
        model: function (newValue) {
            this.onInput()
        },
    },
    methods: {
        onInput() {
            this.$emit('update:modelValue', this.model);
            this.$nextTick(() => {
                this.$emit('input', this.model);
            })

        },
    }
}
</script>

<style scoped>
@import "../../assets//scss/Form/inputTextAppend.scss";
</style>

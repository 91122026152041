<template>
    <div id="affiliate-marketing-analysis-page">
        <div class='container-fluid p-3'>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='row d-flex justify-content-between'>
                                <div class='col-xl-7 col-md-7 col-sm-12 d-flex align-items-center'>
                                    <span v-html="$t('timeSeeData')"></span>
                                    <InputMonth class="mx-2" v-model="filters.time" @update:modelValue="changeTime" />
                                    <button class="btn btn-secondary" @click="clearFilter">
                                        <span>{{ $t('clearFilter') }}</span>
                                    </button>
                                </div>
                                <div class='col-xl-5 col-md-5 col-sm-12 text-right'>
                                    <span class="">
                                        {{ $t('lastUpdated') }} <span v-text="this.lastUpdate ? formatDateCTV(this.lastUpdate, 'HH:mm DD/MM/YYYY') : null"></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='d-md-flex align-items-center'>
                                <div>
                                    <h4 class='card-title'>{{ $t('affiliateMarketingAnalysis') }}</h4>
                                </div>
                            </div>
                            <ul class='nav nav-tabs' role='tablist'>
                                <template v-for="(tab, index) in tabs" :key="index">
                                    <li class='nav-item cursor-pointer'>
                                        <a class='nav-link' :class="filters.tab === tab.id ? 'active' : ''"
                                            @click="changeTab(tab.id)">
                                            <span class='hidden-sm-up'></span>
                                            <span class='hidden-xs-down'>{{ $t(tab?.name) }}</span>
                                        </a>
                                    </li>
                                </template>
                            </ul>

                            <div class="row mt-3" v-if="!filters.tab">
                                <div class="col-md-12 col-lg-12 col-xlg-12" v-if="this.haveDataAnalysisProduct">
                                    <BarChart :labelValues="this.barLabels" :datasetValues="this.barDatasets" :tooltipPrefix="this.$t('revenue')" />
                                </div>
                                <div id="noData" v-else>
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>



                            <!-- <div class="row mt-3" v-if="filters.tab === 1">
                                <div class="col-md-12 col-lg-12 col-xlg-12">
                                    <BarChart :labelValues="this.barLabels" :datasetValues="this.barDatasets"/>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class='row'>
                <div class='col-md-12'>
                    <div class='card'>
                        <div class='card-body'>
                            <div class='row mb-2'>
                                <div class="col-xl-12 col-md-12 col-sm-12">
                                    <h4 class='card-title'>{{ $t('keyElements') }}</h4>
                                </div>
                                <div class="col-xl-4 col-md-4 col-sm-12">
                                    <InputSelectSearch :options="this.partners" :optionValue="'partner_id'"
                                        :defaultValue="this.partner_id"
                                        @searchData="getPartnerHasOrder" @update:modelValue="selectPartner"
                                        :placeholder="this.user?.type == this.$constant.PARTNER.type_partner ? $t('selectCollaborator') : $t('choosePartner')" />
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-md-6 col-lg-4 col-xlg-4">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('amount')">
                                            <label>{{ $t('sales') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.amount?.value) }}đ
                                            </h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span
                                                    :class="this.mainFactor?.amount?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span
                                                        v-text="this.mainFactor?.amount?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.amount?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-4 col-xlg-4">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('products')">
                                            <label>{{ $t('itemsForSale') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.products?.value) }}
                                            </h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span
                                                    :class="this.mainFactor?.products?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span
                                                        v-text="this.mainFactor?.products?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.products?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-4 col-xlg-4">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('orders')">
                                            <label>{{ $t('order') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.orders?.value) }}
                                            </h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span
                                                    :class="this.mainFactor?.orders?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span
                                                        v-text="this.mainFactor?.orders?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.orders?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('commissions')">
                                            <label>{{ $t('estCommission') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.commissions?.value)
                                                }}đ</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span
                                                    :class="this.mainFactor?.commissions?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span
                                                        v-text="this.mainFactor?.commissions?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.commissions?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('ROI')">
                                            <label>{{ $t('roi') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.ROI?.value, 2) }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span
                                                    :class="this.mainFactor?.ROI?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span
                                                        v-text="this.mainFactor?.ROI?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.ROI?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('customers')">
                                            <label>{{ $t('allBuyer') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.customers?.value) }}
                                            </h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span
                                                    :class="this.mainFactor?.customers?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span
                                                        v-text="this.mainFactor?.customers?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.customers?.compare_value) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-lg-3 col-xlg-3">
                                    <div class="card card-hover">
                                        <div class="box border cursor-pointer" @click="changeType('new_customers')">
                                            <label>{{ $t('newBuyer') }}</label>
                                            <h3 class="text-dark">{{ numberFormat(this.mainFactor?.new_customers?.value)
                                                }}</h3>
                                            <div class="d-flex justify-content-between">
                                                <span>{{ $t('compareTheSamePeriod') }}</span>
                                                <span
                                                    :class="this.mainFactor?.new_customers?.compare_value >= 0 ? 'text-success' : 'text-danger'">
                                                    <span
                                                        v-text="this.mainFactor?.new_customers?.compare_value >= 0 ? '&#9650;' : '&#9660;'"></span>
                                                    {{ numberFormatABS(this.mainFactor?.new_customers?.compare_value)
                                                    }}%
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='' v-if="!filters.tab">
                                <h4 class='card-title'>{{ $t('marketingSourceAnalysis') }}</h4>
                                <div class="d-flex justify-content-center" v-if="this.haveDataMarketingChannel">
                                    <DoughnutChart :labelValues="this.doughnutLabels"
                                        :datasetValues="this.doughnutDatasets" />
                                </div>
                                <div id="noData" v-if="!this.haveDataMarketingChannel">
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>

                            <div class='d-md-flex align-items-center justify-content-between mb-1'>
                                <div>
                                    <h4 class='card-title'>{{ $t('productTop') }}</h4>
                                </div>
                                <button class="btn btn-outline-secondary" @click="exportTopProduct">
                                    <span>{{ $t('exportData') }}</span>
                                </button>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-index table-bordered">
                                    <thead class="thead-light border">
                                        <tr>
                                            <th scope="col">{{ $t('rank') }}</th>
                                            <th scope="col">{{ $t('productInformation') }}</th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort"
                                                    :name="$t('sales')" :field="'amount'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort"
                                                    :name="$t('numberOfProductSold')" :field="'quantity'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort"
                                                    :name="$t('order')" :field="'orders'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort"
                                                    :name="$t('estCommission')" :field="'commissions'" />
                                            </th>
                                            <th scope="col">
                                                <TableSortField :current="sortData.field" @onsort="sort"
                                                    :name="$t('roi')" :field="'ROI'" />
                                            </th>
                                            <th scope="col">{{ $t('totalBuyer') }}</th>
                                            <th scope="col">{{ $t('newBuyer') }}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="customtable">
                                        <template v-for="(product, index) in topProduct" :key="product">
                                            <tr>
                                                <td class="text-danger">{{ index + 1 }}</td>
                                                <td>
                                                    <div class="info-products d-flex">
                                                        <div class="image">
                                                            <img :src="product?.product_image"
                                                                v-if="product?.product_image" />
                                                        </div>
                                                        <div class="product mx-1">
                                                            <p>
                                                                <a class="sidebar-link has-arrow waves-effect waves-dark"
                                                                    target="_blank"
                                                                    :href="urlPharmaProduct(product?.product_slug)"
                                                                    aria-expanded="false">
                                                                    {{ product?.product_name }}
                                                                </a>
                                                            </p>
                                                            <p>
                                                                {{ $t('skuId') }}: {{ product?.product_sku }}
                                                            </p>
                                                            <p>
                                                                {{ $t('price') }}: {{
                                                                numberFormat(product?.product_price) }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{ numberFormat(product?.amount) }}</td>
                                                <td>{{ numberFormat(product?.quantity) }}</td>
                                                <td>{{ numberFormat(product?.orders) }}</td>
                                                <td>{{ numberFormat(product?.commissions) }}</td>
                                                <td>{{ numberFormat(product?.ROI, 2) }}</td>
                                                <td>{{ numberFormat(product?.customers) }}</td>
                                                <td>{{ numberFormat(product?.new_customers) }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                                <div id="noData" v-if="this.topProduct?.length == 0">
                                    <span>{{ $t('noData') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading" />
    </div>
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat, numberFormatABS, formatDateCTV } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import LineChart from '@/components/ChartJS/LineChart.vue';
import DoughnutChart from '@/components/ChartJS/DoughnutChart.vue';
import BarChart from '@/components/ChartJS/BarChart.vue';
import InputMonth from '@/components/Form/InputMonth.vue'
import filters from '@/filters';
import { mapGetters } from 'vuex';
import TableSortField from '@/components/TableSortField.vue';
import InputSelectSearch from '@/components/Form/InputSelectSearch.vue'
export default {
    name: 'AffiliateMarketingAnalysis',
    setup() {
        return { numberFormat, numberFormatABS, formatDateCTV };
    },
    components: {
        Daterangepicker,
        Loading,
        InputSelect,
        LineChart,
        DoughnutChart,
        BarChart,
        InputMonth,
        TableSortField,
        InputSelectSearch
    },
    data() {
        return {
            isLoading: false,
            tabs: [
                { id: 0, name: 'allSource' },
            ],
            filters: {
                tab: 0,
                time: moment().format('YYYY-MM')
            },
            haveDataAnalysisProduct: true,
            doughnutLabels: [],
            doughnutDatasets: [],
            sortData: {
                field: '',
                direction: '',
            },
            barLabels: [],
            barDatasets: [],
            partner_id: null,
        }
    },
    mounted() {
        this.getChannels();
        this.getPartnerHasOrder();
        this.getPartnerForAnalysisChannel();
        this.getDataTopProductForAnalysisProduct();
        this.getDataMarketingChannelForAnalysisProduct();
        this.getDataMainFactorForAnalysisProduct();
    },
    computed: {
        ...mapGetters({
            partners: 'partners/partner_have_order',
            topProduct: 'performanceStore/getDataTopProductForAnalysisProduct',
            mainFactor: 'performanceStore/getDataMainFactorForAnalysisProduct',
            role: 'auth/userRole',
            user: 'auth/userInfo',
        })
    },
    methods: {
        clearFilter(){
            this.partner_id = null;
            this.filters = {
                tab: 0,
                time: moment().format('YYYY-MM')
            }
            this.getPartnerHasOrder();
            this.getPartnerForAnalysisChannel();
            this.getDataTopProductForAnalysisProduct();
            this.getDataMarketingChannelForAnalysisProduct();
            this.getDataMainFactorForAnalysisProduct();
        },
        changeTab(tab) {
            this.filters.tab = tab;
            this.getDataTopProductForAnalysisProduct();
            this.getDataMarketingChannelForAnalysisProduct();
            this.getDataMainFactorForAnalysisProduct()
        },
        async getChannels() {
            const res = await this.$store.dispatch('marketingChannels/getListMarketingChannelHasRevenue', {
                dateTimeSearch: this.filters.time
            });
            this.tabs = [{ id: 0, name: 'allSource' }];
            if (res) {
                this.tabs = [...this.tabs, ...res];
            }
        },
        async getDataMainFactorForAnalysisProduct() {
            if (this.user && this.user.type == this.$constant.PARTNER.type_partner) {
                this.filters.partner_id = this.user?.id;
            }
            const res = await this.$store.dispatch('performanceStore/getDataMainFactorForAnalysisProduct', this.filters);
        },
       
        changeTime() {
            this.filters.tab = 0;
            this.getChannels();
            this.getPartnerForAnalysisChannel();
            this.getPartnerHasOrder();
            this.getDataTopProductForAnalysisProduct();
            this.getDataMainFactorForAnalysisProduct();
            this.getDataMarketingChannelForAnalysisProduct();
        },
        async getPartnerForAnalysisChannel() {
            if (this.filters.tab) {
                return;
            }
            if (this.user && this.user.type == this.$constant.PARTNER.type_partner) {
                this.filters.partner_id = this.partner_id;
            }
            const res = await this.$store.dispatch('performanceStore/getPartnerForAnalysisChannel', this.filters);
            this.barLabels = res?.labels;
            this.barDatasets = res?.datasets;
            this.haveDataAnalysisProduct = res?.have_data;
            this.lastUpdate = res?.last_update;

            // this.tabs = [{ id: 0, name: 'allSource' }];
            // if(res?.marketing_channels){
            //     this.tabs.push(...res?.marketing_channels);
            // }
        },
        async getDataTopProductForAnalysisProduct(partner_id = null) {
            // if (this.user && this.user.type == this.$constant.PARTNER.type_partner) {
            //     this.filters.partner_id = this.user?.id;
            // }
            this.filters.partner_or_contributor_id = partner_id;
            const res = await this.$store.dispatch('performanceStore/getDataTopProductForAnalysisProduct', { ...this.filters, ...this.sortData });
        },
        async getPartnerHasOrder(keyword = null) {
            await this.$store.dispatch('partners/getPartnerHasOrder', { keyword: keyword, time: this.filters.time });
        },
        selectPartner(id) {
            this.partner_id = id;
            this.getDataMarketingChannelForAnalysisProduct(this.partner_id);
            this.getDataMainFactorForAnalysisProduct();
            this.getDataTopProductForAnalysisProduct(this.partner_id);
        },

        async getDataMarketingChannelForAnalysisProduct(partner_id) {
            if (this.filters.tab) {
                return;
            }
            this.filters.partner_or_contributor_id = partner_id;
            const res = await this.$store.dispatch('performanceStore/getDataMarketingChannelForAnalysisProduct', this.filters);
            this.doughnutLabels = res?.labels;
            this.doughnutDatasets = res?.datasets;
            this.haveDataMarketingChannel = res?.have_data;
        },
        sort(field, direction){
            this.sortData.field = field;
            this.sortData.direction = direction;
            this.getDataTopProductForAnalysisProduct();
        },
        async exportTopProduct(){
            const res = await this.$store.dispatch('performanceStore/exportTopProductForAnalysisProduct', {...this.filters, ...this.sortData});
            if(res){
                this.$store.dispatch('setShowModalExport', true)
            }
        },
        changeType(type){
            this.filters.type = type;
            this.getDataMarketingChannelForAnalysisProduct();
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/affiliateMarketingAnalysis.scss";
</style>
<template>
    <div id="input-number__form">
        <label v-if="label" v-text="label"></label>
        <input v-model.trim="model" @input="onInput(true)" :readonly="!!readonly"
            :class="[(errorValues && errorValues.length ? 'error-input' : ''), (append ? 'pr-30' : ''), (classCustom ? classCustom : '')]"
            :placeholder="placeholder || label" :maxlength="this.max ? this.max : ''" id="inputText"
            :type="'text'" :disabled="disabled" class="form-control" autocomplete="off"
            @keydown="handleCheckNumber"
        >
        <ul class="error-label" v-if="errorValues && errorValues.length">
            <li v-for="error in errorValues" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "InputNumber",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'readonly', 'max', 'min', 'id', 'type', 'disabled', 'append', 'classCustom'],
    data() {
        return {
            model: this.modelValue,
            errorValues: this.errors ?? [],
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.model = newValue;
        },
        errors: function (newErrors) {
            this.errorValues = newErrors;
        },
    },
    methods: {
        onInput() {
            this.model = this.setModel(this.model);
            this.errorValues = [];                        
            if(this.model && this.checkNaN()){
                this.errorValues = [this.$t('nan')];
                return;
            }  else {
                this.$emit('update:modelValue', this.model);
                this.$nextTick(() => {
                    this.$emit('input', this.model);
                })
            }          

        },
        checkNaN(){
            const isValidPositiveInteger = (value) => { 
                const regex = /^[0-9]\d*$/; 
                return regex.test(value); 
            };
            if ((!isNaN(this.model) && (this.model !== '' || this.model === 'e')) && isValidPositiveInteger(this.model)) { 
                return false;
            } else {
                return true;
            }
        },
        handleCheckNumber(event){
            const key = event.key;
            if (!/^[0-9]\d*$/.test(key) && key !== 'Backspace' && key !== 'Tab') { 
                event.preventDefault(); 
            }
        },
        setModel(value){
            if(this.max && value > this.max){
                return this.max;
            }else if(this.min && value < this.min){
                return this.min
            }
            return value;
        }
    }
}
</script>

<style scoped>
@import "../../assets//scss/Form/inputNumber.scss";
</style>

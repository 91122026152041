import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import i18n from '@/locales/i18nHelper'
import {ROUTER, PERMISSIONS} from '@/common/constant' 
import Layout from '@/components/Layout/Layout.vue'
import Dashboard from '@/views/Home/Dashboard.vue'
import Login from '@/views/Auth/LoginScreen.vue'
import Register from '@/views/Auth/RegisterScreen.vue'
import Partner from '@/views/Partner/Partner.vue'
import CreatePartner from '@/views/Partner/CreatePartner.vue'
import Orders from '@/views/Orders/Orders.vue'
import Payment from '@/views/Payment/index.vue'
import OrderDetail from '@/views/Orders/OrderDetail.vue'
import OpenCampaign from '@/views/Campaign/OpenCampaign.vue'
import TargetCampaign from '@/views/Campaign/TargetCampaign.vue'
import AffiliateMarketingSources from '@/views/AffiliateMarketingSources/index.vue'
import OpenCampaignContributor from '@/views/CampaignContributor/OpenCampaign.vue'
import OpenCampaignPartner from '@/views/CampaignPartner/OpenCampaign.vue'
import TargetCampaignPartner from '@/views/CampaignPartner/TargetCampaign.vue'
import UpdateCampaignPartner from '@/views/CampaignPartner/UpdateTargetCampaign.vue'
import TargetCampaignContributor from '@/views/CampaignContributor/TargetCampaign.vue'
import CreateTargetCampaign from '@/views/Campaign/CreateTargetCampaign.vue'
import DetailTargetCampaign from '@/views/Campaign/DetailTargetCampaign.vue'
import EditTargetCampaign from '@/views/Campaign/EditTargetCampaign.vue'
import MainAnalyticalData from '@/views/Performance/MainAnalyticalData.vue'
import AnalyticalCampaign from '@/views/Performance/AnalyticalCampaign.vue'
import AnalyticalProduct from '@/views/Performance/AnalyticalProduct.vue'
import AffiliateMarketingAnalysis from '@/views/Performance/AffiliateMarketingAnalysis.vue'
import Collaborator from '@/views/Collaborator/Collaborator.vue'
import CreateCollaborator from '@/views/Collaborator/CreateCollaborator.vue'
import CreateCollaboratorTargetCampaign from '@/views/CampaignContributor/CreateTargetCampaign.vue'
import TargetCampaignDetail from '@/views/CampaignContributor/TargetCampaignDetail.vue'
import Affiliate from '@/views/Affiliate/affiliate.vue'
import ForgetPassword from '@/views/Auth/ForgetPassword.vue'
import CompleteInformation from '@/views/Auth/CompleteInformation.vue'
import ChangePassword from '@/views/Auth/ChangePassword.vue'
import DetailProductOpenAnalytical from '@/views/Performance/DetailProductOpenAnalytical.vue'
import DetailTargetCampaignPartner from '@/views/CampaignPartner/DetailTargetCampaign.vue'
import OpenCampaignProductDetailPartner from '@/views/Performance/OpenCampaignProductDetailPartner.vue'
import DetailCampaignTargetAnalysis from '@/views/Performance/DetailCampaignTargetAnalysis.vue'
import DashboardContributor from '@/views/Home/DashboardContributor.vue'
import UserInfor from '@/views/Auth/UserInfor.vue'
import DashboardPartner from '@/views/Home/DashboardPartner.vue'

const routes: Array<any> = [
    {
        path: ROUTER.dashboard,
        component: Layout,
        children: [
            {
                path: ROUTER.dashboard,
                name: ROUTER.dashboardName,
                meta: {
                    displayName: i18n.global.t('dashboard'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: Dashboard,
            },
            {
                path: ROUTER.partnerCampaign,
                name: ROUTER.partnerCampaignName,
                meta: {
                    displayName: i18n.global.t('partnerCampaign'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: Dashboard,
            },
            {
                path: ROUTER.openCampaign,
                name: ROUTER.openCampaignName,
                meta: {
                    displayName: i18n.global.t('openCampaign'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: OpenCampaign,
            },
            {
                path: ROUTER.targetCampaign,
                name: ROUTER.targetCampaignName,
                meta: {
                    displayName: i18n.global.t('targetCampaign'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: TargetCampaign,
            },
            {
                path: ROUTER.createTargetCampaign,
                name: ROUTER.createTargetCampaignName,
                meta: {
                    displayName: i18n.global.t('targetCampaign') + ' > ' + i18n.global.t('createTargetCampaign'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: CreateTargetCampaign,
            },
            {
                path: ROUTER.detailTargetCampaign,
                name: ROUTER.detailTargetCampaignName,
                meta: {
                    displayName: i18n.global.t('targetCampaign') + ' > ' + i18n.global.t('detailTargetCampaign'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: DetailTargetCampaign,
            },
            {
                path: ROUTER.editTargetCampaign,
                name: ROUTER.editTargetCampaignName,
                meta: {
                    displayName: i18n.global.t('targetCampaign') + ' > ' + i18n.global.t('editTargetCampaign'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: EditTargetCampaign,
            },

            {
                path: ROUTER.partner,
                name: ROUTER.partnerName,
                meta: {
                    displayName: i18n.global.t('partnerManage'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: Partner,
            },
            {
                path: ROUTER.createPartner,
                name: ROUTER.createPartnerName,
                meta: {
                    displayName: i18n.global.t('createPartner'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: CreatePartner,
            },
            {
                path: ROUTER.editPartner,
                name: ROUTER.editPartnerName,
                meta: {
                    displayName: i18n.global.t('editPartner'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: CreatePartner,
            },
            {
                path: ROUTER.performance,
                name: ROUTER.performanceName,
                meta: {
                    displayName: i18n.global.t('performance'),
                    permissions: [PERMISSIONS.ROLE_ADMIN, PERMISSIONS.ROLE_PARTNER]
                },
                component: Dashboard,
            },
            {
                path: ROUTER.orders,
                name: ROUTER.ordersName,
                meta: {
                    displayName: i18n.global.t('orders'),
                    permissions: [PERMISSIONS.ROLE_ADMIN, PERMISSIONS.ROLE_PARTNER, PERMISSIONS.ROLE_CONTRIBUTOR]
                },
                component: Orders,
            },
            {
                path: ROUTER.orderDetail,
                name: ROUTER.orderDetailName,
                meta: {
                    displayName: i18n.global.t('orders') + ' > ' + i18n.global.t('orders'),
                    permissions: [PERMISSIONS.ROLE_ADMIN, PERMISSIONS.ROLE_PARTNER, PERMISSIONS.ROLE_CONTRIBUTOR]
                },
                component: OrderDetail,
            },
            {
                path: ROUTER.payments,
                name: ROUTER.paymentsName,
                meta: {
                    displayName: i18n.global.t('paymentManage'),
                    permissions: [PERMISSIONS.ROLE_ADMIN, PERMISSIONS.ROLE_PARTNER, PERMISSIONS.ROLE_CONTRIBUTOR]
                },
                component: Payment,
            },
            {
                path: ROUTER.resources,
                name: ROUTER.resourcesName,
                meta: {
                    displayName: i18n.global.t('manageResources'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: AffiliateMarketingSources,
            },
            {
                path: ROUTER.mainAnalyticalData,
                name: ROUTER.mainAnalyticalDataName,
                meta: {
                    displayName: i18n.global.t('performance') + ' > ' + i18n.global.t('mainAnalyticalData'),
                    permissions: [PERMISSIONS.ROLE_ADMIN, PERMISSIONS.ROLE_PARTNER]
                },
                component: MainAnalyticalData,
            },
            {
                path: ROUTER.analyticalCampaign,
                name: ROUTER.analyticalCampaignName,
                meta: {
                    displayName: i18n.global.t('performance') + ' > ' + i18n.global.t('analyticalCampaign'),
                    permissions: [PERMISSIONS.ROLE_ADMIN, PERMISSIONS.ROLE_PARTNER]
                },
                component: AnalyticalCampaign,
            },
            {
                path: ROUTER.analyticalProduct,
                name: ROUTER.analyticalProductName,
                meta: {
                    displayName: i18n.global.t('performance') + ' > ' + i18n.global.t('analyticalProduct'),
                    permissions: [PERMISSIONS.ROLE_ADMIN, PERMISSIONS.ROLE_PARTNER]
                },
                component: AnalyticalProduct,
            },
            {
                path: ROUTER.affiliateMarketingAnalysis,
                name: ROUTER.affiliateMarketingAnalysisName,
                meta: {
                    displayName: i18n.global.t('performance') + ' > ' +  i18n.global.t('affiliateMarketingAnalysis'),
                    permissions: [PERMISSIONS.ROLE_ADMIN, PERMISSIONS.ROLE_PARTNER]
                },
                component: AffiliateMarketingAnalysis,
            },
            {
                path: ROUTER.contributorAffiliateOpenCampaign,
                name: ROUTER.contributorAffiliateOpenCampaignName,
                meta: {
                    displayName: i18n.global.t('campaignSideBar') + ' > ' + i18n.global.t('openCampaign'),
                    permissions: [PERMISSIONS.ROLE_CONTRIBUTOR]
                },
                component: OpenCampaignContributor,
            },
            {
                path: ROUTER.openCampaignPartner,
                name: ROUTER.openCampaignPartnerName,
                meta: {
                    displayName: i18n.global.t('contributorAffiliate') + ' > ' + i18n.global.t('openCampaign'),
                    permissions: [PERMISSIONS.ROLE_PARTNER]
                },
                component: OpenCampaignPartner,
            },
            {
                path: ROUTER.targetCampaignPartner,
                name: ROUTER.targetCampaignPartnerName,
                meta: {
                    displayName: i18n.global.t('contributorAffiliate') + ' > ' + i18n.global.t('targetCampaign'),
                    permissions: [PERMISSIONS.ROLE_PARTNER]
                },
                component: TargetCampaignPartner,
            },
            {
                path: ROUTER.detailTargetCampaignPartner,
                name: ROUTER.detailTargetCampaignPartnerName,
                meta: {
                    displayName: i18n.global.t('contributorAffiliate') + ' > ' + i18n.global.t('detailTargetCampaign'),
                    permissions: [PERMISSIONS.ROLE_PARTNER]
                },
                component: DetailTargetCampaignPartner,
            },
            {
                path: ROUTER.updateCampaignPartner,
                name: ROUTER.updateCampaignPartnerName,
                meta: {
                    displayName: i18n.global.t('contributorAffiliate') + ' > ' + i18n.global.t('editTargetCampaign'),
                    permissions: [PERMISSIONS.ROLE_PARTNER]
                },
                component: UpdateCampaignPartner,
            },
            {
                path: ROUTER.contributorAffiliateTargetCampaign,
                name: ROUTER.contributorAffiliateTargetCampaignName,
                meta: {
                    displayName: i18n.global.t('contributorAffiliate') + ' > ' + i18n.global.t('targetCampaign'),
                    permissions: [PERMISSIONS.ROLE_CONTRIBUTOR]
                },
                component: TargetCampaignContributor,
            },
            {
                path: ROUTER.collaborators,
                name: ROUTER.collaboratorsName,
                meta: {
                    displayName: i18n.global.t('manageCollaborator'),
                    permissions: [PERMISSIONS.ROLE_PARTNER]
                },
                component: Collaborator,
            },
            {
                path: ROUTER.createCollaborators,
                name: ROUTER.createCollaboratorsName,
                meta: {
                    displayName: i18n.global.t('createCollaborator'),
                    permissions: [PERMISSIONS.ROLE_PARTNER]
                },
                component: CreateCollaborator,
            },
            {
                path: ROUTER.editCollaborators,
                name: ROUTER.editCollaboratorsName,
                meta: {
                    displayName: i18n.global.t('editCollaborator'),
                    permissions: [PERMISSIONS.ROLE_PARTNER]
                },
                component: CreateCollaborator,
            },
            {
                path: ROUTER.createCollaboratorTargetCampaign,
                name: ROUTER.createCollaboratorTargetCampaignName,
                meta: {
                    displayName: i18n.global.t('targetCampaign') + ' > ' + i18n.global.t('createTargetCampaign'),
                    permissions: [PERMISSIONS.ROLE_PARTNER, PERMISSIONS.ROLE_CONTRIBUTOR]
                },
                component: CreateCollaboratorTargetCampaign,
            },
            {
                path: ROUTER.detailCollaboratorTargetCampaign,
                name: ROUTER.detailCollaboratorTargetCampaignName,
                meta: {
                    displayName: i18n.global.t('targetCampaign') + ' > ' + i18n.global.t('detailTargetCampaign'),
                    permissions: [PERMISSIONS.ROLE_CONTRIBUTOR]
                },
                component: TargetCampaignDetail,
            },
            {
                path: ROUTER.changePassword,
                name: ROUTER.changePasswordName,
                meta: {
                    displayName: i18n.global.t('changePassword'),
                    permissions: [PERMISSIONS.ROLE_ADMIN, PERMISSIONS.ROLE_PARTNER, PERMISSIONS.ROLE_CONTRIBUTOR]
                },
                component: ChangePassword,
            },
            {
                path: ROUTER.performanceDetailProductOpen,
                name: ROUTER.performanceDetailProductOpenName,
                meta: {
                    displayName: i18n.global.t('productPerformance'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: DetailProductOpenAnalytical,
            },
            {
                path: ROUTER.performanceDetailProductOpenPartner,
                name: ROUTER.performanceDetailProductOpenPartnerName,
                meta: {
                    displayName: i18n.global.t('openCampaign') + ' > ' + i18n.global.t('detailOpenCampaign'),
                    permissions: [PERMISSIONS.ROLE_PARTNER]
                },
                component: OpenCampaignProductDetailPartner,
            },
            {
                path: ROUTER.performanceDetailCampaignTarget,
                name: ROUTER.performanceDetailCampaignTargetName,
                meta: {
                    displayName: i18n.global.t('targetCampaignPerformance'),
                    permissions: [PERMISSIONS.ROLE_ADMIN]
                },
                component: DetailCampaignTargetAnalysis,
            },
            {
                path: ROUTER.dashboardContributor,
                name: ROUTER.dashboardContributorName,
                meta: {
                    displayName: i18n.global.t('dashboard'),
                    permissions: [PERMISSIONS.ROLE_CONTRIBUTOR]
                },
                component: DashboardContributor,
            },
            {
                path: ROUTER.dashboardPartner,
                name: ROUTER.dashboardPartnerName,
                meta: {
                    displayName: i18n.global.t('dashboard'),
                    permissions: [PERMISSIONS.ROLE_PARTNER]
                },
                component: DashboardPartner,
            },
            {
                path: ROUTER.userInfor,
                name: ROUTER.userInforName,
                meta: {
                    displayName: i18n.global.t('updateUserInformation'),
                },
                component: UserInfor,
            }
        ],
    },
    {
        path: ROUTER.login,
        name: ROUTER.loginName,
        component: Login,
        meta: {noRequiredAuth: true},
    },
    {
        path: ROUTER.register,
        name: ROUTER.registerName,
        component: Register,
        meta: {noRequiredAuth: true},
    },
    {
        path: ROUTER.affiliate,
        name: ROUTER.affiliateName,
        component: Affiliate,
        meta: {noRequiredAuth: true},
    },
    {
        path: ROUTER.forgetPassword,
        name: ROUTER.forgetPasswordName,
        component: ForgetPassword,
        meta: {noRequiredAuth: true},
    },
    {
        path: ROUTER.completeInformation,
        name: ROUTER.completeInformationName,
        meta: {completeInformation: true},
        component: CompleteInformation,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const checkRole = (role: any, roles: []) => {
    if(roles && role && roles.find(r => r == role)){
        return true;
    }
    return false;
}

router.beforeEach((to, from, next) => {
    if(!to?.matched?.length){
        next({name: ROUTER.dashboardName});
    }
    const token = localStorage.getItem('tokenAffiliate');
    if(!token && !to?.meta?.noRequiredAuth){
        next({name: ROUTER.loginName});
    }else{
        const role = localStorage.getItem('userRoleAffiliate') ?? 0;
        const active = localStorage.getItem('userActiveAffiliate') ?? 'false';
        if(active != 'true' && !to?.meta?.completeInformation && !to?.meta?.noRequiredAuth){
            next({name: ROUTER.completeInformationName});
        }
        if((role && to.meta.permissions && !checkRole(role, (to?.meta?.permissions as [] ?? [])))){
            if (+role == PERMISSIONS.ROLE_CONTRIBUTOR) {
                next({name: ROUTER.dashboardContributorName});
            } else if (+role == PERMISSIONS.ROLE_PARTNER) {
                next({name: ROUTER.dashboardPartnerName});
            } else {
                next({name: ROUTER.dashboardName});
            }
        }else{
            next();
        }
    }
});

export default router
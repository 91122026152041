<template>
    <div class='modal fade' ref="ModalSetCommission" id='ModalSetCommission' aria-labelledby='ModalSetCommission'
        aria-hidden='true' data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-md'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5'>{{ $t('setCommissionCollaborator') }}</h1>
                    <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
                </div>
                <div class='modal-body border'>
                    <div class='row mb-3'>
                        <div class='col-lg-12 col-md-12 col-sm-12'>
                            <div class="form-group row">
                                <label class="col-sm-4 control-label col-form-label">
                                    {{ $t('percentCommission') }}:
                                </label>
                                <div class="col-sm-8">
                                    <InputTextAppend v-model="entry.commission_rate_contributor" :max="100" :min="1" :iconAppend="'%'" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='row'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button @click="save()" class='btn btn-primary mx-2'>
                                <span>
                                    {{ $t('confirm') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from '@/components/Form/InputText.vue';
import SingleDaterangepicker from '@/components/SingleDaterangepicker.vue';
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
import InputTextAppend from '@/components/Form/InputTextAppend.vue';
import { mapGetters } from 'vuex';
import toastr from 'toastr';


export default {
    name: "SetCommission",
    components: {
        InputText,
        InputCheckbox,
        SingleDaterangepicker,
        InputTextAppend
    },
    data() {
        return {
            entry: {
                commission_rate_contributor: 0
            },
            setEndTime: false,
            action: null,
            data: null
        }
    },
    watch: {
        setEndTime(newValue) {
            if (!newValue) {
                this.entry.end_time = null;
            }
        }
    },
    computed: {
        ...mapGetters({
            product_codes: 'campaignStore/product_codes',
            userInfo: 'auth/userInfo'
        }),
    },
    methods: {
        async show(data, action) {
            this.data = data
            this.action = action
            this.entry.commission_rate_contributor = this.data?.open_campaign_contributor?.commission_rate_contributor ?? 0
            window.jQuery(this.$refs.ModalSetCommission).modal('show');
        },
        async save() {
            if(this.entry.commission_rate_contributor <= 0 || this.entry.commission_rate_contributor > 100) {
                toastr.error(this.$t('errSetCommission'));
                return;
            }
            const params = {
                partner_id: this.userInfo.id,
                commission_rate_contributor: this.entry.commission_rate_contributor,
                open_campaign_product_id: this.data?.id,
                product_code: this.data?.product_code,
                ids: this.data || null
            };
            const res = await this.$store.dispatch('productLinks/setCommissionMultiple', params);
                if (!res || res.status === 500) {
                    toastr.error('error server');
                } else if (res.status === 422) {
                    let errors = res.response;
                    Object.keys(errors).forEach(function (key) {
                        toastr.error(errors[key][0]);
                    })
                } else if (res.status === 200) {
                    toastr.success(this.$t('successCommission'));
                    this.clearDataAndHideModal();
                }
        },
        clearDataAndHideModal() {
            this.entry.commission_rate_contributor = 0;
            window.jQuery(this.$refs.ModalSetCommission).modal('hide');
            this.$store.dispatch('productLinks/loadDataIndex', true);
        }
    },
}
</script>

<style scoped lang="scss"></style>

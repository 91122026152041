import moment from "moment";
import toastr from 'toastr';
import i18n from '@/locales/i18nHelper'
import constant from '@/common/constant'
export function clone(item) {
    if (!item) {
        return item;
    } // null, undefined values check

    var types = [Number, String, Boolean],
        result;

    // normalizing primitives if someone did new String('aaa'), or new Number('444');
    types.forEach(function (type) {
        if (item instanceof type) {
        result = type(item);
        }
    });

    if (typeof result == "undefined") {
        if (Object.prototype.toString.call(item) === "[object Array]") {
        result = [];
        item.forEach(function (child, index, array) {
            result[index] = clone(child);
        });
        } else if (typeof item == "object") {
        // testing that this is DOM
        if (item.nodeType && typeof item.cloneNode == "function") {
            result = item.cloneNode(true);
        } else if (!item.prototype) {
            // check that this is a literal
            if (item instanceof Date) {
            result = new Date(item);
            } else {
            // it is an object literal
            result = {};
            for (var i in item) {
                result[i] = clone(item[i]);
            }
            }
        } else {
            // depending what you would like here,
            // just keep the reference, or create new object
            if (false && item.constructor) {
            // would not advice to do that, reason? Read below
            result = new item.constructor();
            } else {
            result = item;
            }
        }
        } else {
        result = item;
        }
    }

    return result;
}

export function parseIntEx(value) {
    if (!value) {
        return 0;
    }

    var ret = 0;
    if (typeof value === "string") {
        value = value.replace(/,/g, "");
        ret = parseInt(value);
    } else {
        ret = parseInt(value);
    }

    return ret ? ret : 0;
}

export function ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function numberFormat(v, n, x = 3, s = ",", c = null) {
    if (!v) {
        return "0";
    }

    v = Number(v);
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\D" : "$") + ")",
        num = Number.isInteger(v) ? v.toFixed(Math.max(0, 0)) : v.toFixed(Math.max(0, ~~n));

    let r = (c ? num.replace(".", c) : num).replace(
        new RegExp(re, "g"),
        "$&" + (s || ",")
    );
    return r;
}

export function flip(array, fillValue) {
    if (!Array.isArray(array)) {
        throw new Error("flip failed. Input is not an Array");
    }

    const n = array.length;
    const obj = {};
    if (fillValue) {
        for (let i = 0; i < n; i++) {
        obj[array[i]] = fillValue;
        }
    } else {
        for (let i = 0; i < n; i++) {
        obj[array[i]] = i;
        }
    }

    return obj;
}

export function humanFileSize(bytes, si = true, dp = 1) {
    bytes = parseInt(bytes);
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + " B";
    }

    const units = si
        ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (
        Math.round(Math.abs(bytes) * r) / r >= thresh &&
        u < units.length - 1
    );

    return bytes.toFixed(dp) + " " + units[u];
}

export function formatDate(tzTime) {
    if (!tzTime) {
        return "";
    }
    const m = moment(tzTime);
    if (m.format("YYYY") === new Date().getFullYear().toString()) {
        return m.format("DD/MM");
    }

    return m.format("DD/MM/YYYY");
}

export const checkPermission = (key, permission) => {
    let check = false;
    permission?.forEach((e) => {
        if (e.action === key) {
            check = true;
        }
    });
    return check;
};

export const getLang = () => localStorage.getItem("languageAdmin") ?? "vi";

export function formatDate2(tzTime) {
    if (!tzTime) {
        return "";
    }
    const m = moment(tzTime);
    if (m.format("YYYY") === new Date().getFullYear().toString()) {
        return m.format("DD-MM");
    }

    return m.format("YYYY-MM-DD");
}

export function removeEmptyValue(obj) {
    return Object.entries(obj)
        .filter(([_, v]) => v != "" && v != null && v != "null")
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

export function buildQuery(data) {
    if (typeof data !== "object") {
        return "";
    }

    const queries = [];
    Object.keys(data).map((k) => {
        queries.push(k + "=" + encodeURIComponent(data[k]));
    });
    return queries.join("&");
}

export function parseQuery(queryString) {
    const query = {};

    if (!queryString) {
        return query;
    }

    const pairs = (
        queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split("=");
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
}

export function copy(src, target) {
    if (!target) {
        target = Array.isArray(src) ? [] : {};
    }

    Object.keys(src).map((k) => {
        target[k] = src[k];
    });

    return target;
}

export function getUrlWithQuery(uri, query = {}) {
    const [path, search] = uri.split("?");
    const current = parseQuery(search);
    copy(query, current);

    if (Object.keys(current).length) {
        return path + "?" + buildQuery(current);
    }

    return path;
}

export const formatAddress = ({ addressDetail, wardName, districtName, provinceName}) => {
    const addressArray = [];
    if (addressDetail) {
        addressArray.push(addressDetail);
    }
    if (wardName) {
        addressArray.push(wardName);
    }
    if (districtName) {
        addressArray.push(districtName);
    }
    if (provinceName) {
        addressArray.push(provinceName);
    }
    return addressArray.length > 0 ? addressArray.join(", ") : "";
};

export function $alert(res) {
    if(res?.message){
        if (res?.code === 200) {
            toastr.success(res?.message);
        } else {
            toastr.error(res?.message);
        }
    }
}

export function getStatusPartnerName(status){
    let statusName = '';
    switch (status) {
        case 0:
            statusName = i18n.global.t('inactive');
            break;
        case 1:
            statusName = i18n.global.t('active');
            break;
        case 2:
            statusName = i18n.global.t('waitForConfirm');
            break;
        case 3:
            statusName = i18n.global.t('reject');
            break;
        case 4:
            statusName = i18n.global.t('sendInvitations');
            break;
    }
    return statusName;
}

export function getTypePartnerName(type){
    let typeName = '';
    switch (type) {
        case 1:
            typeName = i18n.global.t('partner');
            break;
        case 2:
            typeName = i18n.global.t('collaborator');
            break;
    }
    return typeName;
}

export function getPaymentCycleName(cycle){
    if(!cycle){
        return '';
    }
    if(cycle === 1){
        return i18n.global.t('every2Weeks');
    }
    return i18n.global.t('everyMonth');
}

export function isChangeData(objA, objB) {
    const aProps = Object.getOwnPropertyNames(objA);
    const bProps = Object.getOwnPropertyNames(objB);    
    if (aProps.length != bProps.length) {
        return true;
    }
    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];          
        if (objA[propName] !== objB[propName]) {             
            return true;         
        }     
    }
    return false; 
}

export function formatDateCTV(date, format = 'YYYY-MM-DD') {
    return moment(new Date(date)).format(format).toString();
};

export function getStatusPaymentName(status){
    let statusName = '';
    switch (status) {
        case 0:
            statusName = i18n.global.t('rejectPayment');
            break;
        case 1:
            statusName = i18n.global.t('waitForProcess');
            break;
        case 2:
            statusName = i18n.global.t('paid');
            break;
        case 3:
            statusName = i18n.global.t('canceled');
            break;
    }
    return statusName;
}

export function getMethodPaymentName(method){
    let methodName = '';
    switch (method) {
        case 1:
            methodName = i18n.global.t('methodPaymentTransfer');
            break;
        case 2:
            methodName = i18n.global.t('methodPaymentCash');
            break;
    }
    return methodName;
}

export function checkRole(role, roles){
    if(roles && role && roles.find(r => r == role)){
        return true;
    }
    return false;
}

export function generateAffiliateLink(slug, contributor_id, channel_id, campaignId) {
    const domain = process.env.VUE_APP_DOMAIN_AFFILIATE;
    return `${domain}/affiliate?slug=${slug}&contributor=${contributor_id}&channel=${channel_id}&campaign=${campaignId}`;
}

export function linkWebPharma(slug) {
    const link = process.env.VUE_APP_PRODUCT_OMIPHARMA + slug;
    window.open(link, '_blank');
}

export function getTypeCampaignName(type){
    let typeName = '';
    if(type != 1){
        typeName = i18n.global.t('targetCampaign');
    }else{
        typeName = i18n.global.t('openCampaign');
    }
    return typeName;
}

export function debounce(func, wait = 500) {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            // @ts-ignore
            func.apply(this, args)
        }, wait)
        // timeout = setTimeout(func, wait);
    };
}

export function getClassForTargetCampaignStatus(status){
    if(status == i18n.global.t('campaignStatusActive')){
        return 'label-success'
    }else if(status == i18n.global.t('campaignStatusComingSoon')){
        return 'label-warning';
    }
    return 'label-secondary';
}

export function selectedElement(array, value, status){
    const exist = array.includes(value);
    if(status && !exist){
        array.push(value);
    }else if(!status && exist){
        array = array.filter(item => item !== value)
    }
    return array;
}

export function numberFormatABS(v, n = 2, x = 3, s = ",", c = null){
    let r = Math.abs(v);
    return numberFormat(r, n, x, s, c);
}

export function getClassForOrderStatus(status){
    if(!status){
        return 'label-warning';
    }
    if(status == constant.ORDER.status_completed){
        return 'label-success';
    }
    if(status == constant.ORDER.status_canceled){
        return 'label-danger';
    }
    return 'label-secondary';
}

<template>
    <div class='t-3 border-block p-3 row'>
        <div class='row'>
            <div class='col-md-12'>
                <div class='card'>
                    <div class='card-body'>
                        <!-- <div class='d-md-flex align-items-center'>
                            <div>
                                <h4 class='card-title'>{{ $t('mainAnalyticalData') }}</h4>
                            </div>
                        </div> -->
                        <ul class='nav nav-tabs' role='tablist'>
                            <li class='nav-item'>
                                <a class='nav-link'  role='tab'
                                    @click="changeChanel(null)" :class="this.channelId == null ? 'active' : ''">
                                    <span class='hidden-sm-up'></span>
                                    <span class='hidden-xs-down'>{{ $t('allSource') }}</span>
                                </a>
                            </li>
                            <li class='nav-item' v-for="channel in channels" :key="channel.id">
                                <a class='nav-link'  role='tab'
                                    @click="changeChanel(channel.id)" :class="this.channelId == channel.id ? 'active' : ''">
                                    <span class='hidden-sm-up'> </span>
                                    <span class='hidden-xs-down'>{{ channel.name }}</span>
                                </a>
                            </li>
                        </ul>
                        <div class="row mt-3">
                            <div role="button" class="col-md-6 col-lg-3 col-xlg-3"
                                @click="showChart($constant.PAGE.TYPE_CHART_SALES, $t('sales'))">
                                <div class="card card-hover">
                                    <div class="box border">
                                        <span>{{ $t('sales') }}</span>
                                        <h3 class="text-dark">{{ numberFormat(amount_sale) }}đ</h3>
                                        <div class="d-flex justify-content-between">
                                            <span>{{ $t('compareTheSamePeriod') }}</span>
                                            <span v-if="rate_amount_sale >= 0" class="text-success">▲{{
                                                rate_amount_sale }}%</span>
                                            <span v-else class="text-danger">&#9660;{{
                                                this.$filters.formatNegativeNumber(rate_amount_sale) }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3 col-xlg-3">
                                <div role="button" class="card card-hover"
                                    @click="showChart($constant.PAGE.TYPE_CHART_PRODUCTS_SOLD, $t('productSold'))">
                                    <div class="box border">
                                        <span>{{ $t('productSold') }}</span>
                                        <h3 class="text-dark">{{ numberFormat(products_sold) }}</h3>
                                        <div class="d-flex justify-content-between">
                                            <span>{{ $t('compareTheSamePeriod') }}</span>
                                            <span v-if="rate_products_sold >= 0" class="text-success">▲{{
                                                rate_products_sold }}%</span>
                                            <span v-else class="text-danger">&#9660;{{
                                                this.$filters.formatNegativeNumber(rate_products_sold) }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3 col-xlg-3">
                                <div role="button" class="card card-hover"
                                    @click="showChart($constant.PAGE.TYPE_CHART_ORDER, $t('order'))">
                                    <div class="box border">
                                        <span>{{ $t('order') }}</span>
                                        <h3 class="text-dark">{{ numberFormat(quantity_order) }}</h3>
                                        <div class="d-flex justify-content-between">
                                            <span>{{ $t('compareTheSamePeriod') }}</span>
                                            <span v-if="rate_quantity_order >= 0" class="text-success">▲{{
                                                rate_quantity_order }}%</span>
                                            <span v-else class="text-danger">&#9660;{{
                                                this.$filters.formatNegativeNumber(rate_quantity_order)
                                                }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3 col-xlg-3">
                                <div role="button" class="card card-hover"
                                    @click="showChart($constant.PAGE.TYPE_CHART_COMMISSION, $t('estCommission'))">
                                    <div class="box border">
                                        <span>{{ $t('estCommission') }}</span>
                                        <h3 class="text-dark">{{ numberFormat(commission) }}đ</h3>
                                        <div class="d-flex justify-content-between">
                                            <span>{{ $t('compareTheSamePeriod') }}</span>
                                            <span v-if="rate_commission >= 0" class="text-success">▲{{
                                                rate_commission }}%</span>
                                            <span v-else class="text-danger">&#9660;{{
                                                this.$filters.formatNegativeNumber(rate_commission) }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3 col-xlg-3">
                                <div role="button" class="card card-hover"
                                    @click="showChart($constant.PAGE.TYPE_CHART_ROI, 'ROI')">
                                    <div class="box border">
                                        <span>{{ $t('roi') }}</span>
                                        <h3 class="text-dark">{{ numberFormat(roi, 2) }}</h3>
                                        <div class="d-flex justify-content-between">
                                            <span>{{ $t('compareTheSamePeriod') }}</span>
                                            <span v-if="rate_roi >= 0" class="text-success">▲{{ rate_roi }}%</span>
                                            <span v-else class="text-danger">&#9660;{{
                                                this.$filters.formatNegativeNumber(rate_roi) }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3 col-xlg-3">
                                <div role="button" class="card card-hover"
                                    @click="showChart($constant.PAGE.TYPE_CHART_ALL_BUYER, $t('allBuyer'))">
                                    <div class="box border">
                                        <span>{{ $t('allBuyer') }}</span>
                                        <h3 class="text-dark">{{ numberFormat(all_buyers) }}</h3>
                                        <div class="d-flex justify-content-between">
                                            <span>{{ $t('compareTheSamePeriod') }}</span>
                                            <span v-if="rate_all_buyers >= 0" class="text-success">▲{{
                                                rate_all_buyers }}%</span>
                                            <span v-else
                                                class="text-danger">&#9660;{{this.$filters.formatNegativeNumber(rate_all_buyers)
                                                }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-3 col-xlg-3">
                                <div role="button" class="card card-hover"
                                    @click="showChart($constant.PAGE.TYPE_CHART_ALL_BUYER_NEW, $t('newBuyer'))">
                                    <div class="box border">
                                        <span>{{ $t('newBuyer') }}</span>
                                        <h3 class="text-dark">{{ numberFormat(new_buyer) }}</h3>
                                        <div class="d-flex justify-content-between">
                                            <span>{{ $t('compareTheSamePeriod') }}</span>
                                            <span v-if="rate_new_buyer >= 0" class="text-success">▲{{ rate_new_buyer
                                                }}%</span>
                                            <span v-else class="text-danger">&#9660;{{
                                                this.$filters.formatNegativeNumber(rate_new_buyer) }}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <label>{{ nameChart }}</label>
                                <LineChart :labelValues="labels" :datasetValues="datasets" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='row' v-if="!channelId">
            <div class='col-md-12'>
                <div class='card'>
                    <div class='card-body'>
                        <h4 class='card-title'>{{ $t('marketingSourceAnalysis') }}</h4>
                        <div class="d-flex justify-content-center" v-if="have_data">
                            <DoughnutChart :labelValues="this.doughnutLabels" :datasetValues="this.doughnutDatasets" />
                        </div>
                        <div id="noData" v-else>
                            <span>{{ $t('noData') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class='row'>
            <div class='col-md-12'>
                <div class='card'>
                    <div class='card-body'>
                        <div class='d-md-flex align-items-center'>
                            <div>
                                <h4 class='card-title'>{{ $t('performanceProduct') }}</h4>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <InputText :placeholder="$t('productSearch')" v-model.trim="productSearch" />
                        </div>
                        <div class="table-responsive">
                            <table class="table table-index table-bordered">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('productInformation') }}</th>
                                        <th scope="col">{{ $t('sales') }}</th>
                                        <th scope="col">{{ $t('numberOfProductSold') }}</th>
                                        <th scope="col">{{ $t('order') }}</th>
                                        <th scope="col">{{ $t('estCommission') }}</th>
                                        <th scope="col">{{ $t('roi') }}</th>
                                        <th scope="col">{{ $t('totalBuyer') }}</th>
                                        <th scope="col">{{ $t('newBuyer') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <tr v-for="(productTop, index) in productsTop" :key="index">
                                        <td>
                                            <div class="info-products d-flex">
                                                <div class="image">
                                                    <img :src="productTop.product.main_image" />
                                                </div>
                                                <div class="product mx-1">
                                                    <p>
                                                        <a class="sidebar-link has-arrow waves-effect waves-dark" target="_blank" :href="urlPharmaProduct(productTop?.product?.slug)" aria-expanded="false">
                                                            {{ productTop?.product?.name }}
                                                        </a>
                                                        <!-- <router-link to="#">
                                                            {{ productTop.product.name ?? null }}
                                                        </router-link> -->
                                                    </p>
                                                    <p>
                                                        {{ $t('skuId') }}: {{
                                                        productTop.product.product_variant_max_price.sku ?? null }}
                                                    </p>
                                                    <p>
                                                        {{ $t('price') }}: {{
                                                        numberFormat(productTop.product.product_variant_max_price.price
                                                        ?? 0) }}
                                                    </p>

                                                </div>
                                            </div>
                                        </td>
                                        <td>{{ numberFormat(productTop.sum_amount) }}</td>
                                        <td>{{ numberFormat(productTop.sum_quantity) }}</td>
                                        <td>{{ numberFormat(productTop.count_order) }}</td>
                                        <td>{{ numberFormat(productTop.sum_commission) }}</td>
                                        <td>{{ numberFormat(productTop.roi, 2) }}</td>
                                        <td>{{ numberFormat(productTop.count_buyer) }}</td>
                                        <td>{{ numberFormat(productTop.count_buyer_new) }}</td>
                                    </tr>
                                    <tr v-if="!productsTop || !productsTop.length">
                                        <td colspan="9" class="text-center">
                                            <span>{{ $t('noData') }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Loading :loading="isLoading" />
</template>

<script>
import Daterangepicker from '@/components/Daterangepicker.vue'
import { numberFormat } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import LineChart from '@/components/ChartJS/LineChart.vue';
import DoughnutChart from '@/components/ChartJS/DoughnutChart.vue';
import i18n from '@/locales/i18nHelper'
import InputText from '@/components/Form/InputText.vue';
import { debounce } from '@/utils/utils';
import { mapGetters } from 'vuex';
let app;

export default {
    name: 'TargetCampaignPartnerAnalysis',
    setup() {
        return { numberFormat };
    },
    components: {
        Daterangepicker,
        Loading,
        LineChart,
        DoughnutChart,
        InputText,
    },
    props: {
        // Nhận prop dateTimeSearch từ component cha
        dateTimeSearch: {
            type: String, // Hoặc loại dữ liệu khác như Date, Number tùy thuộc vào dữ liệu bạn nhận
            required: true
        }
    },
    created() {
        this.getCampaignId();
        this.getChannels();
        this.getDataForChannelMainAnalysis(true);
    },
    mounted() {
        this.getDataRoundChartForChannel();
        this.getTopProductsForChannel();
    },
    data() {
        app = this;
        return {
            campaignId: null,
            channels: [],
            channelId: null,
            all_buyers: null,
            amount_sale: null,
            commission: null,
            new_buyer: null,
            products_sold: null,
            quantity_order: null,
            roi: null,
            rate_channelId: null,
            rate_all_buyers: null,
            rate_amount_sale: null,
            rate_commission: null,
            rate_new_buyer: null,
            rate_products_sold: null,
            rate_quantity_order: null,
            rate_roi: null,
            typeSrc: this.$constant.PAGE.TYPE_CHART_SALES,
            labels: [],
            datasets: [],
            nameChart: i18n.global.t('sales'),
            productsTop: [],
            topPartners: [],
            isLoading: false,
            doughnutLabels: [],
            doughnutDatasets: [],
            isCallMain: false,
            productSearch: null,
            last_update: null,
            have_data: false
        }
    },
    computed: {
        ...mapGetters({
            statusClearFilter: 'statusClearFilter'
        })
    },
    watch: {
        dateTimeSearch() {
            this.channelId = null;
            this.getChannels();
            this.getDataForChannelMainAnalysis();
            this.getDataBarChartForChannel();
            this.getDataRoundChartForChannel();
            this.getTopProductsForChannel();
        },
        productSearch() {
            this.onSearch()
        },
        statusClearFilter(value){
            if(value){
                this.channelId = null;
                this.getChannels();
                this.getDataForChannelMainAnalysis();
                this.getDataBarChartForChannel();
                this.getDataRoundChartForChannel();
                this.getTopProductsForChannel();
                this.$store.dispatch('setClearFilter', false);
            }
        }
    },
    methods: {
        onSearch: debounce(async () => {
            app.getTopProductsForChannel();
        }),
        getCampaignId() {
            this.campaignId = this.$route.query.id;
        },
        async getTopProductsForChannel() {
            if (!this.dateTimeSearch || !this.campaignId) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getTopProductsForChannel', {
                dateTimeSearch: this.dateTimeSearch,
                campaignId: this.campaignId,
                productSearch: this.productSearch,
                channelId: this.channelId,
                campaign_performance_partner: true
            });
            if (res) {
                this.productsTop = res;
            }
        },
        async getDataRoundChartForChannel() {
            if (!this.dateTimeSearch || !this.campaignId) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getDataRoundChartForChannel', {
                dateTimeSearch: this.dateTimeSearch,
                campaignId: this.campaignId,
                campaign_performance_partner: true
            });

            if (res) {
                this.doughnutLabels = res.doughnutLabels;
                this.doughnutDatasets = res.doughnutDatasets;
                this.have_data = res.have_data
            }
        },
        showChart(typeSrc, nameChart) {
            this.typeSrc = typeSrc;
            this.nameChart = nameChart;
            this.getDataBarChartForChannel();
        },
        async getDataBarChartForChannel() {
            if (!this.dateTimeSearch) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getDataBarChartForChannel', {
                channelId: this.channelId,
                dateTimeSearch: this.dateTimeSearch,
                typeSrc: this.typeSrc,
                campaignId: this.campaignId,
                campaign_performance_partner: true
            });
            if (res) {
                this.datasets = res.datasets
                this.labels = res.labels
            }
        },
        changeChanel(channelId) {
            this.channelId = channelId;
            this.getDataForChannelMainAnalysis();
            this.getDataBarChartForChannel();
            this.getTopProductsForChannel();
        },
        async getChannels() {
            const res = await this.$store.dispatch('marketingChannels/getListMarketingChannelHasRevenue', {
                dateTimeSearch: this.dateTimeSearch,
                campaign_id: this.campaignId,
                campaign_performance_partner: true
            });
            if (res) {
                this.channels = res;
            }
        },
        async getDataForChannelMainAnalysis(callDataBarChart = false) {
            if (!this.dateTimeSearch || this.isCallMain || !this.campaignId) {
                return;
            }
            const res = await this.$store.dispatch('performanceStore/getDataForChannelMainAnalysis', {
                channelId: this.channelId,
                dateTimeSearch: this.dateTimeSearch,
                campaignId: this.campaignId,
                campaign_performance_partner: true
            });
            if (res) {
                this.all_buyers = res.all_buyers;
                this.amount_sale = res.amount_sale;
                this.commission = res.commission;
                this.new_buyer = res.new_buyer;
                this.products_sold = res.products_sold;
                this.quantity_order = res.quantity_order;
                this.roi = res.roi;
                this.rate_channelId = res.rate_channelId;
                this.rate_all_buyers = res.rate_all_buyers;
                this.rate_amount_sale = res.rate_amount_sale;
                this.rate_commission = res.rate_commission;
                this.rate_new_buyer = res.rate_new_buyer;
                this.rate_products_sold = res.rate_products_sold;
                this.rate_quantity_order = res.rate_quantity_order;
                this.rate_roi = res.rate_roi;
                this.$store.dispatch('performanceStore/loadDataLastUpdate', res.last_updated);  
            }
            this.isCallMain = false;
            if (callDataBarChart) {
                this.getDataBarChartForChannel();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/createTargetCampaign.scss";
</style>
import i18n from '@/locales/i18nHelper'

export const ROUTER = {
    dashboard: '/',
    login: '/login',
    register: '/register',
    partnerCampaign: '/partner-campaign',
    openCampaign: '/open-campaign',
    targetCampaign: '/target-campaign',
    detailTargetCampaign: '/detail-target-campaign',
    editTargetCampaign: '/edit-target-campaign',
    partner: '/partner',
    createPartner: '/create-partner',
    editPartner: '/edit-partner',
    performance: '/performance',
    orders: '/orders',
    orderDetail: '/order-detail/:id',
    payments: '/payments',
    resources: '/resources',
    mainAnalyticalData: '/main-analytical-data',
    analyticalCampaign: '/analytical-campaign',
    analyticalProduct: '/analytical-product',
    affiliateMarketingAnalysis: '/affiliate-marketing-analysis',
    contributorAffiliate: "/contributor-affiliate",
    contributorAffiliateOpenCampaign: "/contributor/open-campaign",
    openCampaignPartner: "/partner/open-campaign",
    targetCampaignPartner: "/partner/target-campaign",
    detailTargetCampaignPartner: '/partner/detail-target-campaign-partner',
    updateCampaignPartner: "/partner/update-campaign/:id",
    contributorAffiliateTargetCampaign: "/contributor/target-campaign",
    createTargetCampaign: '/create-target-campaign',
    collaborators: '/collaborators',
    createCollaborators: '/create-collaborators',
    editCollaborators: '/edit-collaborators',
    createCollaboratorTargetCampaign: '/create-collaborator-target-campaign',
    detailCollaboratorTargetCampaign: '/contributor/target-campaign/:id',
    affiliate: '/affiliate',
    forgetPassword: '/forget-password',
    completeInformation: '/complete-information',
    changePassword: '/change-password',
    performanceDetailProductOpen: '/performance/detail-product-open',
    performanceDetailProductOpenPartner: '/partner/performance/detail-product-open',
    dashboardContributor: '/contributor/dashboard',
    performanceDetailCampaignTarget: '/performance/detail-campaign-Target',
    userInfor: '/user-information',
    dashboardPartner: '/partner/dashboard',


    dashboardName: 'dashboardName',
    loginName: 'loginName',
    registerName: 'registerName',
    partnerCampaignName: 'partnerCampaignName',
    openCampaignName: 'openCampaignName',
    targetCampaignName: 'targetCampaignName',
    detailTargetCampaignName: 'detailTargetCampaignName',
    editTargetCampaignName: 'editTargetCampaignName',
    partnerName: 'partnerName',
    createPartnerName: 'createPartnerName',
    editPartnerName: 'editPartnerName',
    performanceName: 'performanceName',
    ordersName: 'ordersName',
    orderDetailName: 'orderDetailName',
    paymentsName: 'paymentsName',
    resourcesName: 'resourcesName',
    mainAnalyticalDataName: 'mainAnalyticalDataName',
    analyticalCampaignName: 'analyticalCampaignName',
    analyticalProductName: 'analyticalProductName',
    affiliateMarketingAnalysisName: 'affiliateMarketingAnalysisName',
    contributorAffiliateOpenCampaignName: 'contributorAffiliateOpenCampaignName',
    openCampaignPartnerName: 'openCampaignPartnerName',
    targetCampaignPartnerName: 'targetCampaignPartnerName',
    detailTargetCampaignPartnerName: 'detailTargetCampaignPartnerName',
    updateCampaignPartnerName: 'updateCampaignPartnerName',
    contributorAffiliateTargetCampaignName: 'contributorAffiliateTargetCampaignName',
    createTargetCampaignName: 'createTargetCampaignName',
    collaboratorsName: 'collaboratorsName',
    createCollaboratorsName: 'createCollaboratorsName',
    editCollaboratorsName: 'editCollaboratorsName',
    createCollaboratorTargetCampaignName: 'createCollaboratorTargetCampaignName',
    detailCollaboratorTargetCampaignName: 'detailCollaboratorTargetCampaignName',
    affiliateName: 'affiliateName',
    forgetPasswordName: 'forgetPasswordName',
    completeInformationName: 'completeInformationName',
    changePasswordName: 'changePasswordName',
    performanceDetailProductOpenName: 'performanceDetailProductOpenName',
    performanceDetailCampaignTargetName: 'performanceDetailCampaignTargetName',
    userInforName: 'userInforName',
    performanceDetailProductOpenPartnerName: 'performanceDetailProductOpenPartnerName',
    dashboardContributorName: 'dashboardContributorName',
    dashboardPartnerName: 'dashboardPartnerName',
}

export const PERMISSIONS = {
    PER_DASHBOARD: 'home',
    PER_PARTNER: 'Partner',
    PER_PARTNER_CAMPAIGN: 'partner-campaign',
    PER_OPEN_CAMPAIGN: 'open-campaign',
    PER_TARGET_CAMPAIGN: 'target-campaign',
    PER_PERFORMANCE: 'performance',
    PER_ORDERS: 'orders',
    PER_PAYMENT_MANAGE: 'payments',
    PER_RESOURCE_MANAGE: 'resources',
    PER_MAIN_ANALYTICAL_DATA: 'main-analytical-data',
    PER_ANALYTICAL_CAMPAIGN: 'analytical-campaign',
    PER_ANALYTICAL_PRODUCT: 'analytical-product',
    PER_AFFILIATE_MARKETING_ANALYTICAL: 'affiliate-marketing-analysis',
    PER_CONTRIBUTOR_AFFILIATE: "contributor-affiliate",
    PER_CONTRIBUTOR_AFFILIATE_OPEN_CAMPAIGN: "contributor-affiliate-open-campain",
    PER_CONTRIBUTOR_AFFILIATE_TARGET_CAMPAIGN: "contributor-affiliate-target-campain",
    PER_COLLABORATORS: "collaborators",
    PER_CREATE_COLLABORATORS: "create-collaborators",
    PER_EDIT_COLLABORATORS: "edit-collaborators",
    PER_CREATE_COLLABORATOR_TARGET_CAMPAIGN: "create-collaborator-target-campaign",
    PER_DETAIL_COLLABORATOR_TARGET_CAMPAIGN: "detail-collaborator-target-campaign",

    ROLE_ADMIN: 30,
    ROLE_PARTNER: 31,
    ROLE_CONTRIBUTOR: 32,

}

export const DATE_LOCALES = {
    "format": "DD/MM/YYYY",
    "separator": " -- ",
    "applyLabel": i18n.global.t('choose'),
    "cancelLabel": i18n.global.t('cancel'),
    "fromLabel": i18n.global.t('from'),
    "toLabel": i18n.global.t('to'),
    "customRangeLabel": i18n.global.t('option'),
    "daysOfWeek": [
        i18n.global.t('sunday'),
        i18n.global.t('monday'),
        i18n.global.t('tuesday'),
        i18n.global.t('wednesday'),
        i18n.global.t('thursday'),
        i18n.global.t('friday'),
        i18n.global.t('saturday'),
    ],
    "monthNames": [
        i18n.global.t('january'),
        i18n.global.t('february'),
        i18n.global.t('march'),
        i18n.global.t('april'),
        i18n.global.t('may'),
        i18n.global.t('june'),
        i18n.global.t('july'),
        i18n.global.t('august'),
        i18n.global.t('september'),
        i18n.global.t('october'),
        i18n.global.t('november'),
        i18n.global.t('december'),
    ],
    "firstDay": 1
}

export const STATUS_LIST_MARKETING_CHANNELS = [
    {id: 1, name: i18n.global.t('active') },
    {id: 0, name: i18n.global.t('inactive') },
]

export const ACCOUNT_TYPE = [
    { id: 1, name: i18n.global.t("partner") },
    { id: 2, name: i18n.global.t("collaborator") },
]

export const PAGE = {
    record: 25,
    typeGetPartnerAdmin: 1,
    typeGetContributePartner: 2,
    typeSearchPartner: 1,
    typeSearchCollaborator: 2,
    typeAcc: [
        { id: 1, name: i18n.global.t("partner")},
        { id: 2, name: i18n.global.t("collaborator")}
    ],
    optionsNumberOfOrders: [
        { id: 1, name: '0 - 199'},
        { id: 2, name: '200 - 499'},
        { id: 3, name: '500 - 999'},
        { id: 4, name: '1000 - N'}
    ],
    TYPE_CHART_SALES: 1,
    TYPE_CHART_PRODUCTS_SOLD: 2,
    TYPE_CHART_ORDER: 3,
    TYPE_CHART_COMMISSION: 4,
    TYPE_CHART_ROI: 5,
    TYPE_CHART_ALL_BUYER: 6,
    TYPE_CHART_ALL_BUYER_NEW: 7,
    TYPE_CHART_CLICK: 8,
    TYPE_COMMISSION_CONTRIBUTOR: 9,
}   

export const CAMPAIGN = {
    statusActive: 1,
    statusDraft: 0,
    statusClose: 2,
    listStatus: [
        {id: 0, name: i18n.global.t("campaignStatusDraft")},
        {id: 1, name: i18n.global.t("campaignStatusActive")},
        {id: 2, name: i18n.global.t("campaignEnd")},
        {id: 3, name: i18n.global.t("campaignStatusComingSoon")},
        {id: 4, name: i18n.global.t("campaignStatusClose")},
    ],

    listStatusPartner: [
        // {id: 0, name: i18n.global.t("campaignStatusDraft")},
        {id: 1, name: i18n.global.t("campaignStatusActive")},
        {id: 2, name: i18n.global.t("campaignEnd")},
        {id: 3, name: i18n.global.t("campaignStatusComingSoon")},
        {id: 4, name: i18n.global.t("campaignStatusClose")},
    ],
    listCampaignType: [
        {id: 1, name: i18n.global.t("openCampaign")},
        {id: 2, name: i18n.global.t("targetCampaign")},
    ],
    open_campaign_id: 1
}   

export const PAYMENT = {
    status: {
        wait_for_process: 1,
        complete: 2,
        reject: 3,
    },
    listStatus: [
        {id: 1, name: i18n.global.t("waitForPayment")},
        {id: 2, name: i18n.global.t("paid")},
        {id: 3, name: i18n.global.t("rejectPayment")},
    ],
}

export const CONTRIBUTOR = {
    status: {
        in_active: 0,
        active: 1,
        wait_for_confirm: 2,
        reject: 3,
        send_invitation: 4,
    },
    statusListFilter: [
        {id: 1, name: i18n.global.t("active")},
        {id: 0, name: i18n.global.t("inactive")},
        {id: 4, name: i18n.global.t("sendInvitations")},
    ]
}

export const PARTNER = {
    payment_cycle_every_2_week: 1,
    payment_cycle_every_month: 2,
    status_inactive: 0,
    status_active: 1,
    status_wait_confirm: 2,
    status_reject: 3,
    status_send_invitation: 4,
    type_partner: 1,
    type_colloborator: 2,
}

export const NOTICE = {
    type_target_campaign: 1,
    type_complete_payment: 2,
    type_reject_payment: 3,
}

export const ORDER = {
    status_processing: 0,
    status_completed: 1,
    status_canceled: 2,
    status_returned: 3,

    statusListFilter: [
        {id: null, label: i18n.global.t("all")},
        {id: 0, label: i18n.global.t("processing")},
        {id: 2, label: i18n.global.t("canceled")},
        {id: 1, label: i18n.global.t("completed")},
        {id: 3, label: i18n.global.t("returned")},
    ]
}

const CONSTANT = {
    ROUTER: ROUTER,
    PERMISSIONS: PERMISSIONS,
    DATE_LOCALES: DATE_LOCALES,
    STATUS_LIST_MARKETING_CHANNELS: STATUS_LIST_MARKETING_CHANNELS,
    ACCOUNT_TYPE: ACCOUNT_TYPE,
    CAMPAIGN: CAMPAIGN,
    PAGE: PAGE,
    PARTNER: PARTNER,
    PAYMENT: PAYMENT,
    CONTRIBUTOR: CONTRIBUTOR,
    NOTICE: NOTICE,
    ORDER: ORDER,
}
export default CONSTANT;
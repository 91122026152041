<template>
    <div id="create-target-campaign-page">
        <div class='container-fluid p-3'>
            <div class='row p-3'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="d-flex justify-content-between mb-5">
                            <h4 class='card-title mb-2'>{{ $t('selectCollaborator') }}</h4>
                            <button @click="showModalAddUser()" class="btn btn-outline-secondary" v-if="contributors?.length">
                                <i class="fa fa-plus mx-2"></i>
                                <span>{{ $t('addCTV') }}</span>
                            </button>
                        </div>
                        
                        <h6 class='card-title mb-2'>{{ contributors?.length ?? 0 }} {{ $t('collaborator') }}</h6>
                        <div class="table-responsive" v-if="contributors?.length">
                            <table class="table table-index table-bordered">
                                <thead class="thead-light border">
                                    <tr>
                                        <th scope="col">{{ $t('collaboratorName') }}</th>
                                        <th scope="col">{{ $t('numberOfClicked') }}</th>
                                        <th scope="col">{{ $t('numberOfOrders') }}</th>
                                        <th scope="col">{{ $t('action') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(contributor, index) in contributors" :key="index">
                                        <tr>
                                            <td>
                                                <p>{{ contributor.name }}</p>
                                            </td>
                                            <td>{{ contributor.quantity_click ?? 0 }}</td>
                                            <td>{{ contributor.quantity_order ?? 0 }}</td>
                                            <td>
                                                <span :class="contributor.action || !isDisabled() ? '' : 'disabled'">
                                                    <i @click="contributor.action || !isDisabled() ? showModalConfirmDeletePartner(index, contributor.name) : ''"
                                                        class="fa fa-trash-alt"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive d-flex justify-content-center align-items-center flex-column border" v-else>
                            <button @click="showModalAddUser()" class="btn btn-outline-secondary">
                                <i class="fa fa-plus mx-2"></i>
                                <span>{{ $t('addCTV') }}</span>
                            </button>
                            <ul class="error-label" v-if="errors && errors['contributors']?.length">
                                <li v-for="error in errors['contributors']" :key="error" v-text="error"></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row p-3'>
                <div class='col-lg-12'>
                    <div class="card">
                        <div class="d-flex justify-content-between">
                            <h4 class='card-title mb-2'>{{ $t('setupCommission') }}</h4>
                            <div>
                                <button class="btn btn-secondary" role='button' @click="showSetRate()" :disabled="productCodes.length > 0 ? false : true" >
                                    <i class="fa fa-plus mx-2"></i>
                                    <span>
                                        {{ $t('createQuicklyCommission') }}
                                    </span>
                                </button>
                            </div>
                        </div>
                        <h6 class='card-title mb-2'>{{ products?.length ?? 0 }} {{ $t('product') }}</h6>
                        <div class="table-responsive table-products" v-if="products?.length" style="height: 450px !important;">
                            <table class="table table-index table-bordered">
                                <thead class="thead-light border">
                                    <tr>
                                        <th>
                                            <input @click=onCheckAll(!statusCheckAll) :checked="statusCheckAll" type="checkbox"/>
                                        </th>
                                        <th scope="col">{{ $t('product') }}</th>
                                        <th scope="col">{{ $t('price') }}</th>
                                        <th scope="col">{{ $t('distributorCommissionRate') }}</th>
                                        <th scope="col">{{ $t('collaboratorCommissionRate') }}</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <template v-for="(product, index) in products" :key="index">
                                        <tr>
                                            <th>
                                                <input v-model="productCodes" :value="product?.product_code" type="checkbox"/>
                                            </th>
                                            <td class="col-5">
                                                <div class="info-products d-flex">
                                                    <div class="image">
                                                        <img :src="product?.product?.main_image" />
                                                    </div>
                                                    <div class="product mx-1">
                                                        <p>{{ product?.product?.name }}</p>
                                                        <p>
                                                            {{ $t('skuIdCode') }}: {{
                                                                product?.product?.product_variant_max_price.sku }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="col-2">
                                                <span v-if="product?.product?.product_variant_max_price.price">{{
                                                    $filters.formatPrice(product?.product?.product_variant_max_price.price)
                                                }}</span>
                                                <span v-else>0</span>
                                            </td>
                                            <td class="col-2">{{ product.commission_rate_partner }} %</td>
                                            <td class="col-3">
                                                <InputTextAppend v-model="product.commission_rate_contributor" :max="100" :min="1" :errors="this.errors['products.' + index + '.commission_rate_contributor'] ?? []"/>
                                                <!-- <InputTextAppend v-model="product.commission_rate_contributor" :max="100" :min="1" :value="0" /> -->
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive d-flex justify-content-center align-items-center border" v-else>
                            <span>{{ $t('noProduct') }}</span>
                            <!-- <button class="btn btn-outline-secondary" @click="showModalChosePrd()">
                                <i class="fa fa-plus mx-2"></i>
                                <span>{{ $t('createProduct') }}</span>
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class='row p-3'>
                <div class='col-lg-12'>
                    <div class="card">
                        <h4 class='card-title mb-2'>{{ $t('sendInvitations') }}</h4>
                        <div class="form-send-infor mx-0 p-3">
                            <div class="form-group row">
                                <label class="col-sm-2 control-label col-form-label">
                                    {{ $t('campaignName') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-10">
                                    <InputText v-model.trim="name" :placeholder="$t('enterCampaignName')"
                                        :disabled="true"/>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 control-label col-form-label">
                                    {{ $t('campaignTime') }}:<span class="text-danger">*</span>
                                </label>
                                <div class="col-sm-10">
                                    <div class='row'>
                                        <div class='col-lg-6 col-md-12 col-sm-12'>
                                            <div class="d-flex">
                                                <SingleDaterangepicker :placeholder="$t('startTime')"
                                                    :class="!setEndTime ? 'w-100' : ''" v-model="start_time" :disabled="true"/>
                                                <SingleDaterangepicker :placeholder="$t('endTime')" v-if="setEndTime"
                                                    class="mx-2" v-model="end_time" :disabled="true"/>
                                            </div>
                                        </div>
                                        <div class='col-lg-6 col-md-12 col-sm-12 d-flex align-items-end'>
                                            <InputCheckbox :label="$t('setEndTime')" @update:modelValue="setEndTime"
                                                v-model="setEndTime" :disabled="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 control-label col-form-label">
                                    {{ $t('message') }}:
                                </label>
                                <div class="col-sm-10">
                                    <textarea v-model.trim="message" class="form-control"
                                        :placeholder="$t('descriptionOfCampaign')" style="height: 100px;" :disabled="true"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class='row p-3'>
                <div class='col-lg-12 d-flex justify-content-end'>
                    <button class="btn btn-outline-secondary mx-2" @click="cancel()">
                        <span>
                            {{ $t('cancel') }}
                        </span>
                    </button>
                    
                    <button class="btn btn-warning" @click="submitData($constant.CAMPAIGN.statusActive)">
                        <span>
                            {{ $t('save') }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <Loading :loading="isLoading" />
    </div>
    <ModalChooseContributor ref="ModalChooseContributor" />
    <ModalCreateQuicklyCommission ref="ModalCreateQuicklyCommission"/>
    <ModalConfirmDeletePartner :title="$t('removePartner') + partnerNameRemove" :content="$t('contentRemoveContributor')"
        :button="$t('remove')" @confirm="removePartner" ref="ModalConfirmDeletePartner" />
    <ModalConfirmCancelUpdate 
        :title="$t('cancelEditCampaignTitle')" 
        :content="$t('cancelEditCampaign')"
        :button="$t('confirm')"
        @confirm="confirmCancel"
        ref="ModalConfirmCancel"
    />

</template>

<script>
import { numberFormat } from '@/utils/utils';
import Loading from '@/components/Loading.vue';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import SingleDaterangepicker from '@/components/SingleDaterangepicker.vue'
import InputCheckbox from '@/components/Form/InputCheckbox.vue';
import InputTextAppend from '@/components/Form/InputTextAppend.vue';
import ModalConfirmDeletePartner from '@/components/Modal/ModalConfirm.vue';
import toastr from 'toastr';
import store from "@/store";
import { mapGetters, mapActions } from 'vuex';
import ModalChooseContributor from '@/components/Modal/TargetCampaign/ModalChooseContributor.vue';
import ModalCreateQuicklyCommission from '@/components/Modal/TargetCampaign/ModalCreateQuicklyCommission.vue';
import ModalConfirmCancelUpdate from '@/components/Modal/ModalConfirm.vue';

export default {
    name: 'CreateTargetCampaign',
    setup() {
        return { numberFormat };
    },
    components: {
        Loading,
        InputText,
        InputSelect,
        SingleDaterangepicker,
        InputCheckbox,
        InputTextAppend,
        ModalConfirmDeletePartner,
        ModalChooseContributor,
        ModalCreateQuicklyCommission,
        ModalConfirmCancelUpdate
    },
    data() {
        return {
            partnerIndexRemove: null,
            partnerNameRemove: null,
            statusCheckAll: false,
            prdIndexRemove: null,
            prdNameRemove: null,
            isLoading: false,
            setEndTime: false,

            // data submit
            contributors: [],
            products: [],
            start_time: null,
            start_time_for_admin: null,
            end_time_for_admin: null,
            end_time: null,
            name: null,
            message: null,

            campaignId: null,
            targetCampaignPartners: null,
            partnerId: null,
            productCodes: [],
            status: null,
            code: null,
            start_time_origin: null,
            errors: [],
            contributorOrigin: []

        }
    },
    computed: {
        ...mapGetters({
            partnersNew: 'userStore/partnersNew',
            userInfo: 'auth/userInfo',
            commissionRate: 'campaignStore/commission_rate',
        }),
        targetCampaignPartnerInfo() {
            if (this.targetCampaignPartners) {
                return this.targetCampaignPartners.find(item => item.partner_id == this.userInfo.id)
            }  
        }
    },
    watch: {
        partnersNew: {
            handler: function (newData) {
                if (newData && newData.length) {
                    for (let index = 0; index < newData.length; index++) {
                        const contributorNew =  { ...newData[index] };
                        let item = this.contributors.find((contributor) => contributor.id == contributorNew.id);
                        if (!item) {
                            this.contributors.push(contributorNew);
                        }
                    }
                } else {
                    store.dispatch('userStore/addPartners', []);
                }
            },
            deep: true
        },
        setEndTime: function (newStatus) {
            if (!newStatus) {
                this.end_time = null;
            }
        },
        productCodes: {
            handler: function(newPrdCode) {    
                if (newPrdCode.length == this.products.length) {
                    this.statusCheckAll = true;
                } else {
                    this.statusCheckAll = false;
                }
            },
            deep: true
        },
        commissionRate: function (newStatus) {
            let products = [...this.products];
            for (let index = 0; index < this.products.length; index++) {
                const product = this.products[index];
                if (this.productCodes.includes(product.product_code)) {
                    product['commission_rate_contributor'] = newStatus;
                }
            }
            this.onCheckAll(false);
            this.products = products;
        },
    },
    
    created() {
        this.campaignId = this.$route.params.id;
        this.partnerId = this.$route.query.partner;
        this.fetchCampaignDetail()
    },
    methods: {
        async submitData(status) {
            this.errors =[];
            let products = this.formatProductsSubmit();
            const res = await store.dispatch('campaignStore/createCampaignForContributor', {
                products: products,
                start_time: this.start_time,
                end_time: this.end_time,
                message: this.message,
                target_campaign_partner_id: this.targetCampaignPartnerInfo.id,
                status: status,
                start_time_for_admin: this.start_time_for_admin,
                end_time_for_admin: this.end_time_for_admin,
                partner_id: this.targetCampaignPartnerInfo.partner_id,
                campaign_id: this.targetCampaignPartnerInfo.target_campaign_id,
                contributors: this.contributors,
                code: this.code
            });

            if (!res || res.status === 500) {
                toastr.error(this.$t('msgUpdateError'));
            } else if (res.status === 422) {
                this.errors = res.response ?? [];
            } else if (res.status === 200) {
                toastr.success(this.$t('msgUpdateSuccess'));
                this.$router.push(this.$constant.ROUTER.targetCampaignPartner);
            }
        },
        formatProductsSubmit() {
            let prds = [];
            for (let i = 0; i < this.contributors.length; i++) {
                const element = this.contributors[i];
                for (let index = 0; index < this.products.length; index++) {
                    const prd = this.products[index];

                    prds.push({
                        commission_rate_contributor: prd.commission_rate_contributor ?? 0,
                        user_id: element.id,
                        partner_id: element.partner_id,
                        target_campaign_product_id: prd.id,
                        target_campaign_partner_id: this.targetCampaignPartnerInfo.id,
                        campaign_id: this.campaignId
                    });
                }
            }

            return prds;
        },
        showModalConfirmDeletePrd(index, prdNameRemove) {
            this.prdIndexRemove = index;
            this.prdNameRemove = prdNameRemove,
                this.$refs.ModalConfirmDeleteProduct.show();
        },
        showModalAddUser() {
            const ids = this.contributors.map(contributor => contributor.id);
            this.$refs.ModalChooseContributor.show(ids, this.contributors);
        },
        showModalConfirmDeletePartner(index, partnerNameRemove) {
            this.partnerIndexRemove = index;
            this.partnerNameRemove = partnerNameRemove,
                this.$refs.ModalConfirmDeletePartner.show();
        },
        isDisabled() {
            return moment(this.start_time).isSameOrBefore(moment())
        },
        removePartner() {
            this.contributors.splice(this.partnerIndexRemove, 1);
        },
        ...mapActions({
            getDetailCampaign: 'campaignStore/getDetailCampaign',
        }),
        async fetchCampaignDetail() {
            try {
                const result = await this.getDetailCampaign({ id: this.campaignId, partner: this.partnerId });
                this.targetCampaignPartners = result.target_campaign_partners;
                this.products = result.target_campaign_products;
                this.name = result.name;
                this.status = result.status;
                this.code = result.code;
                this.start_time = result.start_time;
                this.start_time_for_admin = result.start_time;
                this.end_time_for_admin = result.end_time;
                this.message = this.targetCampaignPartnerInfo?.message || result.messages || null;
                this.end_time = result.end_time;
                this.setEndTime = result.end_time ? true : false;
                // this.formatContributors(result.target_campaign_contributors);
                this.products = this.mapProductWithFirstRate(this.products, result.target_campaign_contributors);
                this.addNewContributors(result.target_campaign_contributors);
                this.start_time_origin = result.start_time;
            } catch (error) {
                console.error('Error fetching campaign data:', error);
            }
        },

        formatContributors(contributors) {
            this.contributors = contributors?.reduce((acc, item) => {
                const existing = acc.find(entry => entry.id === item.user_id);
                if (!existing) {
                    acc.push({
                        id: item.user_id,
                        name: item.name,
                        partner_id: this.targetCampaignPartnerInfo?.partner_id,
                        quantity_click: item.quantity_click ?? '',
                        quantity_order: item.quantity_order ?? '',
                        action: false
                    });
                }
                return acc;
            }, []);
        },
        mapProductWithFirstRate(products, contributors) {
            return products?.map(product => {
                const firstContributor = contributors?.find(contributor =>
                    contributor.target_campaign_product_id === product.id
                );
                return {
                    ...product,
                    commission_rate_contributor: firstContributor ? firstContributor.commission_rate_contributor : null
                };
            });
        },
        addNewContributors(newContributors) {
            const formattedData = this.formatContributors(newContributors);
            for (let index = 0; index < formattedData?.length; index++) {
                const contributorNew = formattedData[index];
                let existingContributor = this.contributors.find(contributor => contributor.id === contributorNew.id);

                if (!existingContributor) {
                    this.contributors.push(contributorNew);
                }
            }
        },
        onCheckAll(status) {
            this.statusCheckAll = status;
            if (this.statusCheckAll) {
                let productCodes = [];
                for (let index = 0; index < this.products.length; index++) {
                    const product = this.products[index];
                    productCodes.push(product.product_code);
                }
                this.productCodes = productCodes;
            } else {
                this.productCodes = [];
            }
        },
        showSetRate() {
            this.$refs.ModalCreateQuicklyCommission.show();
        },
        setDisabled(){
            const start_time = moment(this.start_time_origin, 'YYYY-MM-DD HH:mm:ss');
            const now = moment();
            if(this.status == this.$constant.CAMPAIGN.statusActive && start_time.isAfter(now)){
                return false;
            }
            return true;
        },
        cancel() {
            this.$refs.ModalConfirmCancel.show();
        },
        confirmCancel() {
            this.$router.push({name: this.$constant.ROUTER.targetCampaignPartnerName});
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/createTargetCampaign.scss";
</style>
<template>
    <div id="change-password-page">
        <div class="row justify-content-center">
            <div class="change-password-page__form">
                <div class="row content p-3">
                    <div class="col-xl-12 col-md-12 col-sm-12">
                        <form @submit="changePassword">
                            <div class="form-content">
                                <div class="form-group position-relative">
                                    <label>{{ $t('currentPassword') }}</label>
                                    <InputText :placeholder="$t('enterCurrentPassword')" v-model="model.current_password" :append="true" :type="this.showPassword ? 'text' : 'password'" :errors="this.errors.current_password ?? []"/>
                                    <i :class="this.showPassword ? 'fa fa-eye-slash eye-icon-slash' : 'fa fa-eye eye-icon'"
                                        @click="togglePasswordType('showPassword')"></i>
                                </div>
                                
                                <div class="form-group position-relative">
                                    <label>{{ $t('newPassword') }}</label>
                                    <InputText :placeholder="$t('enterNewPassword')" v-model="model.new_password" :append="true" :type="this.showNewPassword ? 'text' : 'password'" :errors="this.errors.new_password ?? []"/>
                                    <i :class="this.showNewPassword ? 'fa fa-eye-slash eye-icon-slash' : 'fa fa-eye eye-icon'"
                                        @click="togglePasswordType('showNewPassword')"></i>
                                </div>
                                <div class="form-group position-relative">
                                    <label>{{ $t('confirmNewPassword') }}</label>
                                    <InputText :placeholder="$t('confirmNewPassword')" v-model="model.confirm_password" :append="true" :type="this.showConfirmPassword ? 'text' : 'password'" :errors="this.errors.confirm_password ?? []"/>
                                    <i :class="this.showConfirmPassword ? 'fa fa-eye-slash eye-icon-slash' : 'fa fa-eye eye-icon'"
                                        @click="togglePasswordType('showConfirmPassword')"></i>
                                </div>
                                <div class="form-group">
                                    <label>{{ $t('verifyCodeEmail') }}</label>
                                    <div class="send-code__group">
                                        <InputText :placeholder="$t('enterVerifyCode')" v-model="model.verify_code" :errors="this.errors.verify_code ?? []" class="change-password-form__input input-send-code"/>
                                        <button class="btn btn-success btn-send-code" type="button" @click="sendCode">{{ $t('sendCode') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <button type="submit" class="btn btn-outline-secondary mx-2" @click="cancel">
                                    <i class="fa fa-ban"></i>
                                    <span class="mx-2">
                                        {{ $t('cancel') }}
                                    </span>
                                </button>
                                <button type="submit" class="btn btn-primary" @click="changePassword">
                                    <i class="fa fa-save"></i>
                                    <span class="mx-2">
                                        {{ $t('save') }}
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import InputText from '@/components/Form/InputText.vue';
import toastr from 'toastr';
import { mapGetters } from 'vuex';

export default {
    name: 'ChangePassword',
    components: {InputText},
    data() {
        return {
            model: {
                verify_code: '',
                current_password: '',
                new_password: '',
                confirm_password: '',
            },
            showPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            errors: {},
            content: null,
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'auth/userInfo'
        })
    },
    mounted() {
    },
    methods: {
        clearData(){
            this.model = {
                verify_code: '',
                current_password: '',
                new_password: '',
                confirm_password: '',
            }
        },
        togglePasswordType(item) {
            this[item] = !this[item]
        },
        async sendCode(){
            this.errors = {};
            let params = {
                "type": "email",
                "email": this.userInfo?.email,
                "verify_type": "change_password"
            }
            const res = await this.$store.dispatch('auth/verify', params);
            if(res?.code === 500 && res?.error){
                if(res?.message){
                    toastr.error(res?.message)
                }else if(res?.messages){
                    this.errors = res?.messages;
                }
                return;
            }else if(res?.code === 200){
                toastr.success(res?.message)
            }
        },
        cancel(){
            this.$router.go(-1);
        },
        async changePassword(e){
            e.preventDefault();
            this.errors = {};
            const res = await this.$store.dispatch('auth/changePassword', {
                'email': this.userInfo?.email,
                'current_password': this.model.current_password,
                'new_password': this.model.new_password,
                'confirm_password': this.model.confirm_password,
                'verify_code': this.model.verify_code
            });
            if(res?.code === 500 && res?.error){
                this.errors = res?.messages;
                return;
            }
            if(res?.code === 200){
                toastr.success(this.$t('msgUpdateSuccess'))
                this.clearData();
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/changePassword.scss";
</style>
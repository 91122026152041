<template>
    <div class='modal fade' ref="ModalCreateAffiliateSource" id='ModalCreateAffiliateSource' aria-labelledby='ModalCreateAffiliateSource'
        aria-hidden='true' data-bs-backdrop='false' tabindex="-1">
        <div class='modal-dialog modal-lg'>
            <div class='modal-content'>
                <div class='modal-header'>
                    <h1 class='modal-title fs-5' v-text="modalUpdate === true ? $t('updateAffiliate') : $t('addAffiliate')"></h1>
                    <button type='button' class='btn-close' @click="cancel"></button>
                </div>
                <div class='modal-body'>
                    <div class="row mb-3">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class='info mt-1 d-flex'>
                                <div class='left fw-bold'>{{ $t('sourceName') }}:<span class="text-danger">*</span></div>
                                <div class="right">
                                    <InputText :placeholder="$t('enterSourceName')" v-model="entry.name" :errors="errors?.name ?? []"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class='info mt-1 d-flex'>
                                <div class='left fw-bold'>{{ $t('URL') }}:<span class="text-danger">*</span></div>
                                <div class="right">
                                    <InputText :placeholder="$t('URL')" v-model="entry.url" :errors="errors?.url ?? []"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class='info mt-1 d-flex'>
                                <div class='left fw-bold'>{{ $t('logo') }}:</div>
                                <div class="right">
                                    <InputFile :image="true" :modelValue="entry?.logo" @update:modalValue="changeLogo" :errors="errors?.logo ?? []"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class='info mt-1 d-flex'>
                                <div class='left fw-bold'>{{ $t('status') }}:</div>
                                <div class="right">
                                    <InputSelect :placeholder="$t('status')" :options="this.statusList" v-model="entry.status"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class='row mt-3'>
                        <div class='d-flex justify-content-center mt-3'>
                            <button class='btn btn-secondary mx-2' @click="cancel">
                                {{ $t('cancel') }}
                            </button>
                            <button class='btn btn-primary mx-2' @click="save">
                                <span>
                                    {{ $t('save') }}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <Loading isLoading={isLoading || isFetching || isLoadingUpdate} /> -->
    </div>
    <ModalConfirm 
        :title="$t('confirm')"
        :content="$t('confirmCancelChangeData')"
        ref="ModalConfirm"
        @confirm="hide"
    />
</template>

<script>
import { numberFormat, clone, isChangeData } from '@/utils/utils';
import InputText from '@/components/Form/InputText.vue';
import InputSelect from '@/components/Form/InputSelect.vue';
import {STATUS_LIST_MARKETING_CHANNELS} from '@/common/constant';
import toastr from 'toastr';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import InputFile from '@/components/Form/InputFile.vue';
export default {
    name: "ModalCreateAffiliateSource",
    setup() {
        return { numberFormat, clone, isChangeData };
    },
    components: { InputText, InputSelect, ModalConfirm, InputFile },
    emits: ['reload'],
    props: [],
    data(){
        return {
            statusList: STATUS_LIST_MARKETING_CHANNELS ?? [],
            modalUpdate: false,
            entry: {},
            defaultEntry: {},
            errors: [],
        }
    },
    computed: {},
    mounted(){},
    methods: {
        async save(){
            let data = JSON.parse(JSON.stringify(this.entry));
            if(data.status === null){
                data.status = 0;
            }
            let res;
            if(this.modalUpdate){
                res = await this.$store.dispatch('marketingChannels/updateMarketingChannels', data);
            }else{
                res = await this.$store.dispatch('marketingChannels/createMarketingChannels', data);
            }
            if(res?.data?.errors){
                this.errors = res?.data?.errors;
                return;
            }
            if(res?.result?.code === 200){
                const message = this.modalUpdate ? this.$t('msgUpdateSuccess') : this.$t('msgCreateSuccess');
                toastr.success(message);
                this.closeModal();
            }else{
                const message = this.modalUpdate ? this.$t('msgUpdateError') : this.$t('msgCreateError');
                toastr.error(message);
            }
        },
        showModal(entry = null){
            this.modalUpdate = false;
            this.entry = {name: '', url: '', status: null};
            if(entry !== null){
                this.entry = {...entry};
                this.modalUpdate = true;
            }
            this.defaultEntry = clone(this.entry);
            this.errors = [];
            window.jQuery(this.$refs.ModalCreateAffiliateSource).modal('show');
        },
        closeModal(){
            this.$emit('reload');
            this.hide();
        },
        cancel(){
            if(isChangeData(this.entry, this.defaultEntry)){
                this.$refs.ModalConfirm.show();
                return;
            }
            this.hide();
        },
        hide(){
            window.jQuery(this.$refs.ModalCreateAffiliateSource).modal('hide');
        },
        changeLogo(value){
            this.entry.logo = value;
        },
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/Modal/ModalCreateAffilateSources.scss";
</style>
